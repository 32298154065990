import React, {useState} from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IndividualActiveUser } from './IndividualActiveUser';
import { didLogout } from '../../Common/Logout';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../redux/store';
import { getConfig, validateAccessToken } from '../../../utils/tokens';

interface UserBusinessProfile {
    id: number,
    business_id: string,
    profile_id: string,
    user_profile: string
}

export default (props: any) => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    // const [userData, setUserData] = useState<IndividualActiveUser>();

    const storeData = store.getState();
	const navigate = useNavigate();

    const handleClickOpen = (event: any) => {
		event.preventDefault();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setText(value);
	}

    const getActiveUser = async (viewActiveUserURL: string, config: any) => {
        try {
            const response = await axios.get(viewActiveUserURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const handleDelete = () => {
        if(didLogout()) {
            navigate("/");
        }
        const deleteEnrolledBusinessURL = process.env.REACT_APP_SERVICE_URI + "enrol_your_business/delete-enrolment/" + props.data.id + "/";

		if (text.toLowerCase() === 'delete') {
            validateAccessToken().then((fetchedAccessToken) => {
                const accessToken = fetchedAccessToken || storeData.user.access_token as string;
                axios.delete(deleteEnrolledBusinessURL, getConfig(accessToken))
                    .then((response) => {
                        alert('Deleted user successfully');
                        window.location.reload();
                        handleClose();
                    })
                    .catch((error) => {
                        alert('Sorry unable to delete Enrolled Business at the moment');
                        handleClose();
                        return;
                    });
                });


                // getActiveUser(deleteEnrolledBusinessURL, getConfig(accessToken))
                // .then((data) => {
                //     setUserData(data);
                //     if (data.user_business_profiles.length !== 0) {
                //         data.user_business_profiles.every((businessProfile: UserBusinessProfile) => {
                //             const deleteUserBusinessProfileURL = process.env.REACT_APP_SERVICE_URI + "business_profile/" + businessProfile.profile_id + "/";
                            
                //         })
                //     }

                //     axios.delete(deleteUserURL, getConfig(accessToken))
                //     .then(()=> {
                //         axios.delete(deleteUserAuthURL, getConfig(accessToken))
                //         .then((response) => {
                //             alert('Deleted user successfully');
                //             window.location.reload();
                //             handleClose();
                //         })
                //         .catch((error) => {
                //             alert('Sorry unable to delete user at the moment');
                //             handleClose();
                //         });
                //     })
                //     .catch((error) => {
                //         alert('Sorry unable to delete user at the moment');
                //         handleClose();
                //     });
                // })
                // .catch((error) => {
                //     alert('Loading failed!');
                // });
            // })
		}
	}

    return (
        <div>
        <u style={{color:'#0D6EFD', cursor: 'pointer'}} onClick={handleClickOpen}>UnEnrol</u>

        <Dialog open={open} onClose={handleClose}>
			<DialogTitle>Warning</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Please type <b>Delete</b> to UnEnrol Business <b><i>{props.data.business_details.business_name}</i></b>
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id="delete"
				label="Type here"
				type="text"
				fullWidth
				variant="standard"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
			/>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose}>Cancel</Button>
			<Button onClick={handleDelete}>Delete</Button>
			</DialogActions>
		</Dialog>
      </div>
    );
};