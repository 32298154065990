import axios from "axios";
import React, { useState, useRef, useCallback, useMemo } from 'react';
import {GridReadyEvent, FirstDataRenderedEvent} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import { ActiveUser } from "./ActiveUser";
import ViewActiveUserButton from "./ViewActiveUserButton";
import BusinessOwnerNavbar from "../../BusinessOwnerView/Navigation/BusinessOwnerNavbar";
import DeleteActiveUserButton from "./DeleteActiveUserButton";
import '../../../pages/Common/styles.css';
import { useNavigate } from "react-router-dom";
import { store } from "../../../redux/store";
import { didLogout } from "../../Common/Logout";
import { getConfig, validateAccessToken } from "../../../utils/tokens";

function ActiveUsers(props: any) {

    const gridRef = useRef<AgGridReact<{name: string, email: string, role: string, user_business_profiles: string, modify: string}>>(null);

    const [isFetching, setIsFetching] = useState(false);
    const [activeUsers, setActiveUsers] = useState<ActiveUser[]>([]);

    const activeUsersURL = process.env.REACT_APP_SERVICE_URI + "users/";

    const storeData = store.getState();
	const navigate = useNavigate();

    const containerStyle = useMemo(() => ({ }), []);
    const gridStyle = useMemo(() => ({ }), []);

    const [columnDefs, setColumnDefs] = useState([
        {headerName: 'Name', field: 'name', maxWidth: 600, flex: 2, resizable: true, filter: true},
        {headerName: 'Email', field: 'email', maxWidth: 600, flex: 2, resizable: true, filter: true},
        {headerName: 'Role', field: 'role', maxWidth: 600, flex: 1, resizable: true, filter: true,
            cellRenderer: (params: any) => {
                const viewableRole = params.data.role.split("_")[1];
                return viewableRole.charAt(0).toUpperCase() + viewableRole.slice(1).toLowerCase();
            }
        },
        {headerName: 'User Business Profiles', field: 'user_business_profiles', maxWidth: 600, flex: 2, resizable: true, filter: true,
            cellRenderer: (params: any) => {return params.data.user_business_profiles.length > 1 ? params.data.user_business_profiles[0].company_business_name + '...' : (params.data.user_business_profiles.length ===1 ? params.data.user_business_profiles[0].company_business_name : '')} 
        },
        {field: 'modify', headerName: '', minWidth: 110, flex: 1, resizable: true, cellRenderer: ViewActiveUserButton},
        {field: 'delete', headerName: '', minWidth: 110, flex: 1, resizable: true, cellRenderer: DeleteActiveUserButton},
      ]);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            axios.get(activeUsersURL, getConfig(accessToken))
            .then((response) => {
                setActiveUsers(response.data);
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        })
    }, []);

    const setColumnWidth = () => {
        gridRef.current?.columnApi.autoSizeColumns(['name', 'email', 'role', 'user_business_profiles'], false);
    }
    
    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : null}
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
            <div className="ag-grid-size">
				<div style={containerStyle}>
				    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridRef} // Ref for accessing Grid's API
                            columnDefs={columnDefs}
                            rowData={activeUsers}
                            animateRows={true}
                            domLayout='autoHeight'
                            onGridReady={onGridReady}
                            onFirstDataRendered={setColumnWidth}
                        >
                        </AgGridReact>
					</div>
				</div>
			</div>
        </>
    )
}

export default ActiveUsers;