import React, { useState, useRef, useCallback, useMemo } from 'react';
import axios from "axios";
import {GridReadyEvent, FirstDataRenderedEvent} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ProductBulkUploadButton from './ProductBulkUploadButton';
import ModifySelectedBusinessButton from './ModifySelectedBusinessButton';
import DeleteSelectedBusinessButton from './DeleteSelectedBusinessButton';
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import '../../../pages/Common/styles.css';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { useNavigate } from 'react-router';
import { store } from '../../../redux/store';

function ViewBusinesses(props: any) {
    // const gridRef = useRef<AgGridReact<{company_business_name: string, edit: any}>>(null);
    const navigate = useNavigate();

    const [isFetching, setIsFetching] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    
    const viewBusinessesURL = process.env.REACT_APP_SERVICE_URI + "businesses/";

    const storeData = store.getState();

    const containerStyle = useMemo(() => ({ }), []);
    const gridStyle = useMemo(() => ({ }), []);

    const [columnDefs, setColumnDefs] = useState([
    { headerName: 'Business Name', field: 'company_business_name', maxWidth: 600, flex: 3.5, resizable: true, filter: true},
    {
        field: 'modify',
        headerName: '',
        flex: 1, 
        resizable: true,
        cellRenderer: ModifySelectedBusinessButton
    },
    {
        field: 'delete', 
        headerName: '', 
        flex: 1, 
        resizable: true, 
        cellRenderer: DeleteSelectedBusinessButton}
    ]);  

    const onGridReady = useCallback((params: GridReadyEvent) => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            axios.get(viewBusinessesURL, getConfig(accessToken))
            .then((response) => {
        	    setBusinesses(response.data);
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        });
    }, []);

    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : null}

            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
            <div className='ag-grid-size'>
				<div style={containerStyle}>
					<div style={gridStyle} className="ag-theme-alpine">
						<AgGridReact
							// ref={gridRef} // Ref for accessing Grid's API
                            columnDefs={columnDefs}
							rowData={businesses}
							animateRows={true}
							domLayout='autoHeight'
							onGridReady={onGridReady}
							// onFirstDataRendered={setColumnWidth}
						>
                  		</AgGridReact>
					</div>
				</div>
			</div>
        </>
    )
}

export default ViewBusinesses;