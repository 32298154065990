import React, { useState, useRef, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from "axios";
import ViewPendingUser from './ViewPendingUser';
import {GridReadyEvent, FirstDataRenderedEvent} from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import '../../../pages/Common/styles.css';
import { store } from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import { CircularProgress } from '@mui/material';

function PendingUsers(props: any) {

  	const gridRef = useRef<AgGridReact<{name: string, email: string}>>(null);

  	const pendingUsersURL = process.env.REACT_APP_SERVICE_URI + "pending/";

  	const containerStyle = useMemo(() => ({ }), []);
  	const gridStyle = useMemo(() => ({ }), []);

	const storeData = store.getState();
	const navigate = useNavigate();

  	const [rowData, setRowData] = useState();
  	const [isFetching, setIsFetching] = useState(false);

	const [columnDefs, setColumnDefs] = useState([
		{headerName: 'Name', field: 'name', maxWidth:600, flex: 1, resizable: true, filter: true},
		{headerName: 'Email', field: 'email', maxWidth:600, flex: 1, resizable: true, filter: true},
		{field: 'viewPendingUser', cellRenderer: ViewPendingUser},
	]);

	// const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
	//   gridRef.current!.api.sizeColumnsToFit();
	// }, []);

  	const onGridReady = useCallback((params: GridReadyEvent) => {
		if(didLogout()) {
            navigate("/");
        }
		setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;

			axios.get(pendingUsersURL, getConfig(accessToken))
			.then((response) => {
				setRowData(response.data);
				setIsFetching(false);
			})
			.catch((error) => {
				setIsFetching(false);
				alert('Loading failed!');
			});
		})
  	}, []);

  	return (
    	<>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : null}
			<Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
			<div className='ag-grid-size'>
				<div style={containerStyle}>
					<div style={gridStyle} className="ag-theme-alpine">
						<AgGridReact
							ref={gridRef} // Ref for accessing Grid's API
							columnDefs={columnDefs}
							rowData={rowData}
							animateRows={true}
							domLayout='autoHeight'
							onGridReady={onGridReady}
						>
						</AgGridReact>
					</div>
				</div>
			</div>
      	</>
  	)
}

export default PendingUsers;