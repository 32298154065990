import React, { useState } from "react";
import { Trash, Trash2 } from 'react-bootstrap-icons'
import Spacer from "./Spacer";

const ProductBulkFileUploadOption = ({ productBulkFile, setProductBulkFile}: any) => {

    const [errorMessage, setErrorMessage] = useState("");
    
    const handleProductBulkFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        const file = event.target.files[0];
        if (file) {
            const fileSizeInKB = file.size / 1024; // Convert bytes to KB
            if (fileSizeInKB > 400) {
                setErrorMessage("File size exceeds 250 KB. Please upload a smaller file.");
                setProductBulkFile(null);
            } else {
                setErrorMessage("");
                setProductBulkFile(file);
            }
        }
    }

    return (
        
        <center>
            <div className="form-group">
            {errorMessage && (
                <div style={{ color: "red", marginBottom: "10px" }}>
                    {errorMessage}
                </div>
            )}

            {productBulkFile && (
                <div>
                    <p><strong>Uploaded File:</strong> {productBulkFile.name}</p>
                    <p><strong>File Size:</strong> {(productBulkFile.size / 1024).toFixed(2)} KB</p>
                    <button
                        type="button"
                        className="btn btn-outline-danger mt-2"
                        onClick={() => setProductBulkFile(null)}
                    >
                        <Trash2 size={20} /> Remove
                    </button>
                </div>
            )}
            <Spacer height={40} />
            <input
                type="file"
                id="productBulkFile"
                name="productBulkFile"
                accept=".xls, .xlsx"
                onChange={handleProductBulkFileUpload}
                className="form-control"
            />
            </div>
        </center>
    );
};

export default ProductBulkFileUploadOption;