import React, {useState, useEffect} from 'react';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import ReactButton from 'react-bootstrap/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { BusinessProfile } from './BusinessProfile';
import Box from '@mui/material/Box';
import { IndividualActiveUser } from './IndividualActiveUser';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../../../pages/Common/styles.css';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { CircularProgress, Modal } from '@mui/material';


interface ActiveUserDialogProps {
    userId: string,
    showSelectedUser: boolean,
    setShowSelectedUser: React.Dispatch<React.SetStateAction<any>>,
    userData: IndividualActiveUser,
    setUserData: React.Dispatch<React.SetStateAction<any>>
}

interface DialogTitleProps{
    children: any,
    id: string,
    onClose: any
}

interface DeleteBusinessProfileDialogProps{
    selectedBusiness: string | undefined,
    selectedUserBusinessProfileId: string,
    openDeleteUserBusinessProfileDialog: boolean,
    userBusinessProfileIds: {business_id: string, profile_id: string}[],
    setUserBusinessProfileIds: React.Dispatch<React.SetStateAction<any>>,
    handleCloseDeleteUserBusinessProfileDialog: () => void
}

const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    border: 1,
    p: 1,
    m: -1.5,
    mt: 0
};

function DeleteBusinessProfileDialog(props: DeleteBusinessProfileDialogProps) {
    const [deleteUserBusinessProfileDialogText, setDeleteUserBusinessProfileDialogText] = useState('');

    const storeData = store.getState();

    const handleChangeDeleteUserBusinessProfileDialogText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
    	setDeleteUserBusinessProfileDialogText(value);
    }

    const handleDeleteUserBusinessProfile = () => {
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const deleteUserBusinessProfileURL = process.env.REACT_APP_SERVICE_URI + "business_profile/" + props.selectedUserBusinessProfileId + "/";
            if (deleteUserBusinessProfileDialogText.toLowerCase() === 'delete') {
                axios.delete(deleteUserBusinessProfileURL, getConfig(accessToken))
                .then((response) => {
                    props.setUserBusinessProfileIds(props.userBusinessProfileIds.filter((userBusinessProfileId) =>
                        userBusinessProfileId.profile_id !== props.selectedUserBusinessProfileId
                    ))

                    alert('Deleted user profile successfully');
                    props.handleCloseDeleteUserBusinessProfileDialog();
                })
                .catch((error) => {
                    alert('Sorry unable to delete user profile at the moment');
                });
            }
        })
    }

    return (
        <Dialog open={props.openDeleteUserBusinessProfileDialog} onClose={props.handleCloseDeleteUserBusinessProfileDialog}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please type Delete if you want to delete profile for <i>{props.selectedBusiness}</i>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="delete"
                    label="Type here"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeDeleteUserBusinessProfileDialogText(event)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCloseDeleteUserBusinessProfileDialog}>Cancel</Button>
                <Button onClick={handleDeleteUserBusinessProfile}>Delete</Button>
            </DialogActions>
		</Dialog>
    )
}

function ViewActiveUserDialog(props: ActiveUserDialogProps) {

    const [businesses, setBusinesses] = useState<{id: string, company_business_profile_id: String, company_business_name: string, is_user_business_profile_attached: boolean}[]>([]);
    const [selectedBusiness, setSelectedBusiness] = useState<string>();
    const [selectedBusinessName, setSelectedBusinessName] = useState<string>();
    const [selectedCompanyBusinessProfileId, setSelectedCompanyBusinessProfileId] = useState<string>('');
    const [selectedUserBusinessProfileId, setSelectedUserBusinessProfileId] = useState<string>('');
    const [selectedFeatures, setSelectedFeatures] = useState<{title: string, feature_id: string}[]>([]);
    const [availableFeatures, setAvailableFeatures] = useState<{title: string, feature_id: string}[]>([]);
    const [userBusinessProfileIds, setUserBusinessProfileIds] = useState<{business_id: string, profile_id: string}[]>([]);
    const [existingBusiness, setExistingBusiness] = useState<boolean>(false);
    const [loaded, setLoaded] = useState(false);
    const [openDeleteUserBusinessProfileDialog, setOpenDeleteUserBusinessProfileDialog] = useState<boolean>(false);
    const [deleteUserBusinessProfileDialogText, setDeleteUserBusinessProfileDialogText] = useState('');

    const [isFetching, setIsFetching] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const storeData = store.getState();
	const navigate = useNavigate();

    const getBusinesses = async (getBusinessesURL: string, config: any) => {
        try {
            const response = await axios.get(getBusinessesURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const getBusiness = async (getBusinessURL: string, config: any) => {
        try {
            const response = await axios.get(getBusinessURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const getUserBusinessProfile = async (businessProfileURL: string, config: any) => {
        try {
            const response = await axios.get(businessProfileURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            setLoaded(false);
            setSelectedBusiness('');
            setUserBusinessProfileIds([]);

            //@ts-ignore
            if (props.userData.user_business_profiles.length !== 0) {
                setSelectedUserBusinessProfileId(props.userData.user_business_profiles[0].profile_id as string);
                setExistingBusiness(true);
                props.userData.user_business_profiles.every(businessProfile => {
                    //@ts-ignore
                    setUserBusinessProfileIds(prevState => [...prevState, {business_id: businessProfile.business_id, profile_id: businessProfile.profile_id}]);
                    return true;
                })
                const businessProfileURL = process.env.REACT_APP_SERVICE_URI + "business_profile/" + props.userData.user_business_profiles[0].profile_id;
                
                getUserBusinessProfile(businessProfileURL, getConfig(accessToken))
                .then((businessData) => {
                    // setUserBusinessProfile(businessData);

                    setSelectedFeatures(businessData.nix_connect_app_profiles);

                    setSelectedBusiness(businessData.business_id); 
                    if (businessData.business_id) {
                        const getBusinessURL = process.env.REACT_APP_SERVICE_URI + "businesses/" + businessData.business_id + "/";
                        getBusiness(getBusinessURL, getConfig(accessToken))
                        .then((data) => {
                            setSelectedCompanyBusinessProfileId(data.company_business_profile.id);
                            setAvailableFeatures(data.nix_connect_app_profiles);
                        })
                        .catch((error) => {
                            setIsFetching(false);
                            alert("Couldn't load business data!");
                        });
                    }
                    setIsFetching(false);
                })
                .catch((error) => {
                    setIsFetching(false);
                    alert('Loading failed!');
                })
            }
            else{
                setIsFetching(false);
            }
            
            const getBusinessesURL = process.env.REACT_APP_SERVICE_URI + "businesses/";
            getBusinesses(getBusinessesURL, getConfig(accessToken))
            .then((data) => {
                setBusinesses(data);
            })
            .catch((error) => {
                alert('Loading failed!');
            });

            setLoaded(true);
        })

    }, []);

    const handleRoleChange = (event: SelectChangeEvent) => {
        props.setUserData((prevState: any) => {
            return {...prevState, role: event.target.value}
        }) 
    };

    const handleBusinessSelect = (event: SelectChangeEvent) => {
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            setSelectedFeatures(prevState => []);
            setSelectedBusiness(event.target.value);
            
            props.userData.user_business_profiles.every((user_business_profile)=>{
                if (user_business_profile.business_id === event.target.value) {
                    setSelectedUserBusinessProfileId(user_business_profile.profile_id as string);
                    setExistingBusiness(true);
                    const businessProfileURL = process.env.REACT_APP_SERVICE_URI + "business_profile/" + user_business_profile.profile_id;
                    getUserBusinessProfile(businessProfileURL, getConfig(accessToken))
                    .then((businessData) => {
                        // setUserBusinessProfile(businessData);
                        setSelectedFeatures(businessData.nix_connect_app_profiles);
                    })
                    return false;
                } else {
                    setExistingBusiness(false);
                    return true;
                }
            })

            const getBusinessURL = process.env.REACT_APP_SERVICE_URI + "businesses/" + event.target.value + "/";

            getBusiness(getBusinessURL, getConfig(accessToken))
            .then((data) => {
                setSelectedCompanyBusinessProfileId(data.company_business_profile.id);
                setAvailableFeatures(data.nix_connect_app_profiles);
            })
            .catch((error) => {
                alert("Couldn't load business data!");
            });
        })
    };

    const handleOnChangeFeatures = (event: any) => {
        const title = event.target.value;
        const feature_id = event.target.id;
        const isChecked = event.target.checked;

        if(feature_id === 'selectAll') {
            if (isChecked) {
                setSelectedFeatures(prevState => []);
                availableFeatures.map(availableFeature => {
                    let feature = Object.assign({}, {title: availableFeature.title, feature_id: availableFeature.feature_id});
                    setSelectedFeatures(prevState => [...prevState, feature])
                })
            } else {
                setSelectedFeatures(prevState => []);
            }
        } else {
            if (isChecked) {
                setSelectedFeatures([...selectedFeatures, {'title': title, 'feature_id': feature_id}])
            } else {
                const filteredList = selectedFeatures.filter((item) => item.feature_id !== feature_id);
                setSelectedFeatures(filteredList);
            }
        }
    }

    const handleCreateUserBusinessProfile = () => {
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const getUserBusinessProfileIdURL = process.env.REACT_APP_SERVICE_URI + "business_profile/";

            axios.post(getUserBusinessProfileIdURL, {
                nix_connect_app_profiles: selectedFeatures,
                email: props.userData.email,
                user_profile_id: props.userId,
                is_user_attached: true,
                business_id: selectedBusiness,
                company_business_profile_id: selectedCompanyBusinessProfileId
            }, getConfig(accessToken))
            .then((response) => {
                //@ts-ignore
                setUserBusinessProfileIds(prevState => [...prevState, {business_id: selectedBusiness, profile_id : response.data.id}]);
                alert("Successfully created Business Profile for user");
            })
            .catch((error) => {
                alert("We're Sorry. Unable to create business user profile right now!");
            })
        })
    }

    const handleModifyUserBusinessProfile = () => {
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const getUserBusinessProfileIdURL = process.env.REACT_APP_SERVICE_URI + "business_profile/" + selectedUserBusinessProfileId + "/";

            axios.patch(getUserBusinessProfileIdURL, {
                nix_connect_app_profiles: selectedFeatures,
                email: props.userData.email,
                user_profile_id: props.userId,
                is_user_attached: true,
                business_id: selectedBusiness,
                company_business_profile_id: selectedCompanyBusinessProfileId
            }, getConfig(accessToken))
            .then((response) => {
                if (response.data.message.toLowerCase() === 'success') {
                    setUserBusinessProfileIds(userBusinessProfileIds.filter((userBusinessProfileId) => userBusinessProfileId.business_id !== selectedBusiness));
                    //@ts-ignore
                    setUserBusinessProfileIds(prevState => [...prevState, {business_id:selectedBusiness, profile_id : selectedUserBusinessProfileId}]);
                    alert("Successfully modified Business Profile for user");  
                } else {
                    alert("We're Sorry. Unable to modify business user profile right now!");
                }
            })
            .catch((error) => {
                alert("We're Sorry. Unable to modify business user profile right now!");
            })
        })
    }

    const handleViewUserDetailsSave = () => {

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const activeUserId = props.userId;
            const activeUserURL = process.env.REACT_APP_SERVICE_URI + "users/" + activeUserId + "/";
            axios.patch(activeUserURL, {
                name: props.userData.name,
                email: props.userData.email,
                role: props.userData.role,
                user_business_profiles: userBusinessProfileIds
            }, getConfig(accessToken))
            .then((response) => {
                if(window.confirm('Modified user successfully')) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                if(window.confirm('Unable to modify user at the moment')) {
                    window.location.reload();
                }
            });

            props.setShowSelectedUser(false);
        })
    };

    const handleOpenDeleteUserBusinessProfileDialog = (event: any) => {
		event.preventDefault();
		setOpenDeleteUserBusinessProfileDialog(true);
	};

	const handleCloseDeleteUserBusinessProfileDialog = () => {
		setOpenDeleteUserBusinessProfileDialog(false);
	};

    function BootstrapDialogTitle(dialogTitleProps: DialogTitleProps) {
        const { children, onClose, ...other } = dialogTitleProps;
      
        return (
          <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
        );
    }

    const handleShowActiveUser = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        props.setShowSelectedUser(value)
    }

    return (
        <Dialog fullScreen={fullScreen} open={props.showSelectedUser} aria-labelledby="responsive-dialog-title" 
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1100px",
                    },
                },
            }}
        >
            <DeleteBusinessProfileDialog selectedBusiness={selectedBusiness} selectedUserBusinessProfileId={selectedUserBusinessProfileId} openDeleteUserBusinessProfileDialog={openDeleteUserBusinessProfileDialog} userBusinessProfileIds={userBusinessProfileIds} setUserBusinessProfileIds={setUserBusinessProfileIds} handleCloseDeleteUserBusinessProfileDialog={handleCloseDeleteUserBusinessProfileDialog} />
            <BootstrapDialogTitle id="customized-dialog-title" onClose={(event: any) => handleShowActiveUser(event, false)}>
                User Details
            </BootstrapDialogTitle>
            <DialogContent>
                <Modal
                    open={isFetching}
                    style={{
                        position: 'absolute',
                        height: 50,
                        width: 100,
                        margin: 'auto'
                        }}
                >
                    <CircularProgress />
                </Modal>
                <form>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="name" label="Name" aria-describedby="nameHelp" value={props.userData.name} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="email" className="form-control me-3 col" id="email" label="Email" aria-describedby="emailHelp" value={props.userData.email} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <FormControl className="form-control col">
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={props.userData.role}
                                label="Role"
                                onChange={handleRoleChange}
                            >
                                <MenuItem value={'BUSINESS_OWNER'}>Owner</MenuItem>
                                <MenuItem value={'BUSINESS_MEMBER'}>Member</MenuItem>
                                <MenuItem value={'GENERAL_USER'}>User</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <label>Contact Details</label>
                    <div className='form-group my-2 row'>
                        <TextField type="number" className="form-control me-3 col" id="mobileNumber" label="Mobile Number" aria-describedby="mobileNumberHelp" value={props.userData.contact_details.mobile} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="email" className="form-control me-3 col" id="alternateEmail" label="Alternate Email" aria-describedby="alternateEmailHelp" value={props.userData.contact_details.alt_email} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="number" className="form-control col" id="landlineNumber" label="Landline Number" aria-describedby="landlineNumberHelp" value={props.userData.contact_details.landline} variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>

                    <label>Address</label>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="addressLine1" label="Address Line 1" aria-describedby="addressLine1Help" value={props.userData.address.address_line_1} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control me-3 col" id="addressLine2" label="Address Line 2" aria-describedby="addressLine2Help" value={props.userData.address.address_line_2} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="number" className="form-control col" id="postCode" label="Post Code" aria-describedby="postCodeHelp" value={props.userData.address.post_code} required variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>
                    <div className='form-group my-2 row'>
                        <TextField type="text" className="form-control me-3 col" id="city" label="City" aria-describedby="cityHelp" value={props.userData.address.city} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control me-3 col" id="state" label="State" aria-describedby="stateHelp" value={props.userData.address.state} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control col" id="country" label="Country" aria-describedby="countryHelp" value={props.userData.address.country} required variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>
         
                    {/*<label>Tax Info</label>
                    <div className='form-group my-2 row'>
                        <TextField type="text" className="form-control me-3 col" id="abn" label="Abn" aria-describedby="abnHelp" value={props.userData.tax_info.abn} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control me-3 col" id="taxId01" label="Tax Id 01" aria-describedby="taxId01Help" value={props.userData.tax_info.tax_id_01} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control col" id="taxId02" label="Tax Id 02" aria-describedby="taxId02Help" value={props.userData.tax_info.tax_id_02} variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>*/}

                    <Box sx={{ ...commonStyles, borderRadius: 1 }}>

                        <div>
                        {loaded && <label>Business Profile
                            <br />
                            <br />
                            <FormControl className="form-control">
                                <InputLabel>Business</InputLabel>
                                <Select
                                    value={selectedBusiness}
                                    label="Business"
                                    onChange={handleBusinessSelect}
                                >
                                    {businesses.map((item: any) => {
                                        return <MenuItem value={item.id}>{item.company_business_name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </label>}
                        </div>

                        {availableFeatures.length!==0 ? 
                            <>
                                <label>Select Features</label>
                                <div>
                                    <input
                                        type="checkbox"
                                        name="features"
                                        id='selectAll'
                                        value={'Select All'}
                                        checked={availableFeatures.length === selectedFeatures.length}
                                        onChange={handleOnChangeFeatures}
                                    />
                                    <label>Select All</label>
                                    {availableFeatures.map((item: {title: string, feature_id: string}, index) => {
                                        return (
                                            <div key={item.feature_id} className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="features"
                                                    id={item.feature_id}
                                                    value={item.title}
                                                    checked={selectedFeatures.some(selectedFeature => selectedFeature.feature_id === item.feature_id)}
                                                    onChange={handleOnChangeFeatures}
                                                />
                                                <label>{item.title}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div style={{ justifyContent: "d-flex flex-row-reverse"}}>
                                    <center>
                                        <div className="button-container">
                                        {!existingBusiness && <ReactButton variant="primary" onClick={handleCreateUserBusinessProfile}>Create</ReactButton>}
                                        {existingBusiness && <ReactButton variant="primary" onClick={handleModifyUserBusinessProfile}>Modify</ReactButton>}
                                        {existingBusiness && <ReactButton variant="danger" className="btn-clear" style={{marginLeft:5}} onClick={handleOpenDeleteUserBusinessProfileDialog}>Delete</ReactButton>}
                                        </div>
                                    </center>
                                </div>
                            </>
                        : null}
                    </Box>
                    </form>
                </DialogContent>
                <DialogActions>
                    <MuiButton onClick={handleViewUserDetailsSave}>Save</MuiButton>
                    <MuiButton onClick={(event: any) => handleShowActiveUser(event, false)}>Discard</MuiButton>
                </DialogActions>
            </Dialog>
        
    )
}

export default ViewActiveUserDialog;