import React, { useState, useEffect, useMemo } from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import { SelectedOrder } from './SelectedOrder';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import BusinessMemberNavbar from '../../BusinessMemberView/Navigation/BusinessMemberNavbar';
import { store } from '../../../redux/store';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { didLogout } from '../../Common/Logout';
import { LazyLoadImage } from "react-lazy-load-image-component";

export default (props: any) => {

    const [isFetching, setIsFetching] = useState(false);
    const [selectedOrderData, setSelectedOrderData] = useState<SelectedOrder>();
    const [businessLogoURL, setBusinessLogoURL] = useState<string>('');

    const storeData = store.getState();

    let navigate = useNavigate();

    const containerStyle = useMemo(() => ({ width: 550, height: 500 }), []);

    const getSelectedOrderDetails = async (viewSelectedOrderURL: string, config: any) => {
        try {
            const response = await axios.get(viewSelectedOrderURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const orderId = storeData.sales.selectedOrder;
            const viewSelectedOrderURL = process.env.REACT_APP_SERVICE_URI + "sales/" + orderId + "/";
            getSelectedOrderDetails(viewSelectedOrderURL, getConfig(accessToken))
            .then((data) => {
                setSelectedOrderData(data);
                const fetchBusinessLogoURL = process.env.REACT_APP_SERVICE_URI + "businesses/images/" + data.business_id + "/";
                
                axios.get(fetchBusinessLogoURL, getConfig(accessToken))
                .then((businessLogoData) => {
                    if(businessLogoData.data.message.toUpperCase() === 'Success'.toUpperCase()){
                        setBusinessLogoURL(businessLogoData.data.business_logo_path);
                        setIsFetching(false);
                    }
                })
                .catch((error) => {
                    setIsFetching(false);
                })
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        })
    },[]);

    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : <BusinessMemberNavbar />}
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
            
            {selectedOrderData && !isFetching?
            <div className='h-100 d-flex align-items-center justify-content-center'>
                <div style={containerStyle}>
                <div style={{justifyContent:'center', alignItems: 'center', fontFamily: 'monospace'}}>
                    <center>
                        {businessLogoURL && <LazyLoadImage 
                            src={process.env.REACT_APP_SERVICE_URI + businessLogoURL}
                            width={"140px"}
                            height={"140px"}
                            alt="business logo not found"
                            className='mr-auto p-3'
                        />}
                    </center>
                            <center><h3>{selectedOrderData.company_business_name}</h3></center>
                            <center><p>Regn No: {selectedOrderData.business_registration_number}</p></center>
                            <p><b>Invoice # : {selectedOrderData.invoice_number}</b></p>
                            <p>Invoice Date : {selectedOrderData.invoice_date_time}</p>
                            
                            <hr style={{ borderTop: '2px dashed black' }} />

                            {selectedOrderData.products.map((value, index) => 
                                <>
                                    <pre>{index +1}. {value.name}</pre>
                                    <pre>  {value.quantity}x {selectedOrderData.currency_symbol} {value.unit_price} GST {value.gst}%                             {selectedOrderData.currency_symbol} {value.amount}</pre>
                                </>
                            )}
                            
                            <hr style={{ borderTop: '2px dashed black' }} />
                            <pre>Unique items :                                 {selectedOrderData.products.length}</pre>
                            <pre><b>Total No of items :                            {selectedOrderData.number_of_items}</b></pre>

                            <pre>Subtotal <span style={{textAlign: 'right'}}>                                      {selectedOrderData.currency_symbol} {selectedOrderData.invoice_sub_total}</span></pre>
                            <pre>GST                                            {selectedOrderData.currency_symbol} {selectedOrderData.gst_amount}</pre>
                            <pre>Discount                                       {selectedOrderData.currency_symbol} {selectedOrderData.additional_discount}</pre>

                            <pre><b>Total (GST Included)                           {selectedOrderData.currency_symbol}{selectedOrderData.invoice_total}</b></pre>
                            <hr style={{ borderTop: '2px dashed black' }} />
                            <p>
                                Note. : After the sale of a product, we cannot accept returns based on a change of mind. Our policy allows returns 
                                only in the case of defects, and you will be required to provide proof of purchase.
                            </p>

                            <p>
                                We appreciate your purchase. You're welcome to continue ordering products through our online store at {}
                            </p>
                            <center><h3>Thank You</h3></center>
                            <u style={{color:'#0D6EFD', cursor: 'pointer'}} onClick={() => navigate(-1)}>Back</u>
                        </div>
                    </div>
                </div>
            : null}
      </>
    );
};