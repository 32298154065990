import {
  CONTACT_PHONE, 
  CONTACT_EMAIL, 
  TERMS_OF_SERVICE,
  POLICY_LAST_UPDATED_DATE } from '../GlobalValues';

const dataPolicy = [
    {
      section: 'Data Policy for Nix Connect App',
      data: [
         { key: 'Effective Date', value: `${POLICY_LAST_UPDATED_DATE}` },
        { key: 'Introduction', value: 'Welcome to Nix Connect! Nix Connect is the Product from NiXel Systems Pty Ltd. Registered in Australia. This Data Policy outlines how we collect, use, and protect your data when you use our Nix Connect Application. It is crucial to read and understand this policy to make informed decisions about your data and privacy.' },
        { key: 'Information We Collect', value: [
          { key: 'User Provided Business Information', value: [
            'When you create an account, we collect the following information to set up your Business with us:',
            'a. Business Name',
            'b. Business Website URL',
            'c. Address (City, State, Country, Postcode)',
            'd. Business Email',
            'e. Business Mobile',
            'f. Business Landline',
            'g. Bank Details',
            'h. Pay Id Information',
            'i. Tax Details',
            'j. Business Registration Details',
            'k. Additional Company Information if Any',
          ] },
          { key: 'Uploaded Content', value: 'Any content, including images, that you upload to the app may be utilized on our Nix Connect website, for marketing purposes, or for similar functionalities. For more information, please visit Enroll Nix Connect\'s Enroll Form or the Nix Connect website.' },
        ] },
        { key: 'Why does Nix Connect gather the aforementioned information?', value: 'Nix Connect utilizes the provided information to establish and maintain your business profile, securely stored in the Nix Connect Server\'s database. The responsibility to manage and delete these details rests with either the Business Owner or the Nix Connect Admin.' },
        { key: 'User Provided Personal Information', value: [
          'As part of User registration on Nix Connect web, we collect the following details (not available on the Mobile App):',
          'a) Email ID',
          'b) User Name',
          'c) Password',
          'd) Mobile Number',
          'e) Alternate Email ID',
          'f) Landline phone',
          'g) Address (City, State, Country, Postcode)',
        ] },
        { key: 'Automatically Collected Information', value: 'We use Google Firebase Analytics to gather App usage and Analytics reports, including device information and usage data.' },
        { key: 'How We Use Your Information', value: [
          'We use your personal information for the following purposes:',
          'To Provide and Maintain the App',
          'To Personalize User Experience',
          'To Communicate',
        ] },
        { key: 'Data Storage and Security', value: [
          'Your data is securely stored on protected servers. Access to personal details is limited to the user, business owner, and product owner. Passwords are strictly confidential, known only to the user. We adhere to industry standards for password storage, utilizing robust encryption mechanisms for added security.',
          'In unforeseen circumstances, such as global hacking incidents, despite our stringent measures, we may face challenges. Rest assured, we are committed to promptly informing you in the event of any security breach.',
        ] },
        { key: 'Responsibility and Liability', value: [
          'NiXel Systems, including its product Nix Connect, takes responsibility for implementing appropriate technical and organizational measures to safeguard user data. While we strive for robust security, we assume no liability for incidents beyond our control. Users are encouraged to take proactive measures to secure their accounts.',
        ] },
        { key: 'Consent and Age Restrictions', value: 'By using Nix Connect, you affirm that you are of legal age to provide consent. If you are a parent or guardian, you must provide consent on behalf of a minor.' },
        { key: 'Data Sharing and Disclosure', value: 'We do not sell, rent, or lease your personal information. We may share information with your explicit consent or for legal compliance.' },
        { key: 'Your Rights and Choices', value: [
          `Access and Correction: You have the right to access and correct your personal information. Data deletion requests can be made via ${CONTACT_EMAIL}`,
        ] },
        { key: 'Updates to the Data Policy', value: 'We may update this Data Policy periodically. Significant changes will be communicated to users accordingly.' },
        { key: 'Nix Connect Data Retention Policy:', value: 'At Nix Connect, our Data Retention Policy dictates the lifecycle of data within our systems. We retain various types of data, such as customer and operational information, for specific durations in accordance with legal requirements and operational needs. Upon the conclusion of the defined retention period, data is securely and permanently deleted from all systems to ensure compliance with applicable laws. Exceptions to deletion may be permitted for instances of legitimate business purposes or legal obligations. Oversight of this policy is maintained by our Data Protection Officer (DPO), and periodic reviews are conducted to align with evolving business practices and regulations. We are committed to transparency, and individuals seeking clarification or with concerns can contact our DPO for further assistance.' },
        
        { key: 'Contact Us', value: `If you have questions or concerns about our privacy practices, contact us at ${CONTACT_EMAIL}. By using Nix Connect, users acknowledge and accept the terms outlined in this Responsibility and Liability Statement. NiXel Systems reserves the right to update this statement as needed, and users are advised to periodically review it for any changes. This Data Policy provides an overview of how we handle user data within the Nix Connect App.` },
        { key: 'Date of Last Update', value: `${POLICY_LAST_UPDATED_DATE}` },
      ],
    },
  ];
  
  export default dataPolicy;
  