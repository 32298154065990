import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from "axios";
import { SelectedBusinessMemberSales } from './SelectedBusinessMemberSales';
import { AgGridReact } from 'ag-grid-react';
import {GridReadyEvent, FirstDataRenderedEvent} from 'ag-grid-community';
import {useLocation} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import ViewSelectedOrderButton from './ViewSelectedOrderButton';
import DeleteSelectedOrderButton from './DeleteSelectedOrderButton';
import { useNavigate} from "react-router-dom";
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import BusinessMemberNavbar from '../../BusinessMemberView/Navigation/BusinessMemberNavbar';
import { store } from '../../../redux/store';
import '../../../pages/Common/styles.css';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';

export default (props: any) => {

    const [isFetching, setIsFetching] = useState(false);
    const [selectedBusinessMemberSalesData, setSelectedBusinessMemberSalesData] = useState<SelectedBusinessMemberSales>();

    const storeData = store.getState();

    let navigate = useNavigate();

    const containerStyle = useMemo(() => ({ }), []);
    const gridStyle = useMemo(() => ({  }), []);

    const [columnDefs, setColumnDefs] = useState([
        {headerName: 'Invoice Number', field: 'invoice_number', maxWidth: 600, flex: 2, filter: true},
        {headerName: 'Client Name', field: 'client_name', maxWidth: 600, flex: 2, filter: true},
        {headerName: 'Invoice Total', field: 'invoice_total', maxWidth: 600, flex: 2, filter: true, cellRenderer: (params: any) => {return params.data.currency_symbol + params.data.invoice_total + " " + params.data.currency_code}},
        {field: '', flex: 1.5, cellRenderer: ViewSelectedOrderButton},
        {field: '', flex: 1.5, cellRenderer: DeleteSelectedOrderButton},
        // {field: 'downloadSelectedBusinessMemberIndividualSales', cellRenderer: DownloadSelectedBusinessMemberIndividualSales},
    ]);

    const getSelectedBusinessMemberSales = async (viewSelectedBusinessMemberSalesURL: string, config: any) => {
        try {
            const response = await axios.get(viewSelectedBusinessMemberSalesURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const businessProfileId = storeData.sales.selectedBusinessMember.businessProfileId;
            const memberProfileId = storeData.sales.selectedBusinessMember.memberProfileId;
            const viewSelectedBusinessMemberSalesURL = process.env.REACT_APP_SERVICE_URI + "sales/report/" + memberProfileId + "/" + businessProfileId + "/";
            getSelectedBusinessMemberSales(viewSelectedBusinessMemberSalesURL, getConfig(accessToken))
            .then((data) => {
                setSelectedBusinessMemberSalesData(data);
            })
            .catch((error) => {
                alert('Loading failed!');
            });
        });
    },[]);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        setIsFetching(false);
      }, []);

    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : <BusinessMemberNavbar />}
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
            
            {selectedBusinessMemberSalesData ?
                <div className='ag-grid-size'>
                    <div style={containerStyle }>
                        <h5>Business Name: {selectedBusinessMemberSalesData.company_business_name}</h5>
                        <h6>Owner Name: {selectedBusinessMemberSalesData.business_owner_name}</h6>
                        <h6>Member Name : {selectedBusinessMemberSalesData.business_member_name}</h6>
                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                                // ref={gridRef} // Ref for accessing Grid's API
                                columnDefs={columnDefs}
                                rowData={selectedBusinessMemberSalesData.business_member_sales_list}
                                animateRows={true}
                                domLayout='autoHeight'
                                onGridReady={onGridReady}
                                // onFirstDataRendered={onFirstDataRendered}
                            >
                            </AgGridReact>
                        </div>
                        <br />
                        <u style={{color:'#0D6EFD', cursor: 'pointer'}} onClick={() => navigate(-1)}>Back</u>
                        <h6 style={{float: 'right'}}>Total Sales: {selectedBusinessMemberSalesData.currency_symbol}{selectedBusinessMemberSalesData.total_sale_amount} {selectedBusinessMemberSalesData.currency_code}</h6>
                    </div>
                </div>
            : null}
      </>
    );
};