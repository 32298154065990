import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { UserBusiness } from './UserBusiness';
import axios from "axios";  
import '../../../pages/Common/styles.css';
import {useLocation} from "react-router-dom";
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import { Modal } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import {store} from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import BorderedSection from '../../Common/BorderedSection';
import ProductBulkUploadOption from '../../Common/ProductBulkUploadOption';

const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    border: 1,
    ml: -1.5,
    p: 1
};

function ProductBulkUpload(props: any) {
    const navigate = useNavigate();

    const userSlice = store.getState();

    const [selectedFeatures, setSelectedFeatures] = useState<{title: string, feature_id: string}[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const [userBusiness, setUserBusiness] = useState<UserBusiness>(); // user_business_profile_details_data
    const [responseData, setResponseData] = useState<{ success?: any[]; errors?: any[] }>({});
    const [productBulkFile, setProductBulkFile] = useState<File| null>(null);
    const productBulkUploadData = new FormData();

    const [loaded, setLoaded] = useState(false);

    const location = useLocation();

    const storeData = store.getState();

    const getBusinessProfileDetails = async (getBusinessProfileURL: string, config: any) => {
        try {
            const response = await axios.get(getBusinessProfileURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    // This is the End URL - /business_profile/list/2498184173999447/
    // This is the End URL - /business_profile/list/{user_profile_id}/
    const setData = async (accessToken: string) => {
        const businessId = location.state.business_id;
        const getBusinessProfileURL = process.env.REACT_APP_SERVICE_URI + "business_profile/simple_list/" + businessId + "/";

        await getBusinessProfileDetails(getBusinessProfileURL, getConfig(accessToken))
        .then((data) => {
            try {
                if( (data !== null) && (businessId === data.business_id) )  {
                    // Required for later
                    setUserBusiness(Object.assign({}, {
                        id: data.id,
                        business_id: data.business_id,
                        user_profile_id: data.user_profile_id,
                        role: data.role,
                        business_member_profile_id: data.business_member_profile_id,
                        business_owner_name: data.business_owner_name,
                        company_business_profile_id: data.company_business_profile_id,
                        business_owner_profile_id: data.business_owner_profile_id,
                        user_business_profile_id: data.user_business_profile_id,
                        company_business_location: data.company_business_location,
                        business_member_name: data.business_member_name,
                        is_active_business: data.is_active_business,
                        is_banned_business: data.is_banned_business,
                        is_dealer: data.is_dealer     
                    }))
                }
                else {
                    console.warn('Business ID mismatch or no data available');
                }
            } catch (error) {
                console.error('Error fetching business profile details:', error);
            }
        })
        .catch((error) => {
            setIsFetching(false);
            alert('Loading failed!');
        });
    }

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            setLoaded(false);
            setData(accessToken).then(()=>{
                setLoaded(true);
                setIsFetching(false);
            })
        })
    },[]);


    //additionalInfoToPatch: AdditionalInfo,
    const postProductBulkUpload = (productBulkUploadURL: string, businessId: string, config: any) => { 

        if ( businessId && productBulkFile ) {
            productBulkUploadData.append("product_bulk_file", productBulkFile as File);
            productBulkUploadData.append("business_id", businessId as string);
            // Get data from the already set userBusiness object
            const {
                id,
                business_id,
                user_profile_id,
                role,
                business_owner_name,
                business_member_profile_id,
                company_business_profile_id,
                business_owner_profile_id,
                user_business_profile_id,
                company_business_location,
                business_member_name,
                is_active_business,
                is_banned_business,
                is_dealer,
            } = userBusiness || {}; // Fallback to an empty object if userBusiness is undefined
        // Add the userBusiness object to FormData
            productBulkUploadData.append(
                "user_business",
                JSON.stringify({
                    id,
                    business_id,
                    user_profile_id,
                    role,
                    business_owner_name,
                    business_member_profile_id,
                    company_business_profile_id,
                    business_owner_profile_id,
                    user_business_profile_id,
                    company_business_location,
                    business_member_name,
                    is_active_business,
                    is_banned_business,
                    is_dealer,
                })
            );
            
            //@ts-ignore
        config.headers['Content-Type'] = 'multipart/form-data';
        axios.post(productBulkUploadURL, productBulkUploadData, config)
        .then((response) => {
            setIsFetching(false);
            setResponseData(
                {
                    success: response.data.success || [],
                    errors: response.data.errors || [],
                }
            );

        })
        .catch((error) => {
            setIsFetching(false);
            alert('Unable to upload file. Please try after some time.');
        })
        }
    }

    const handleProductBulkUpload = (event: any) => {
        event.preventDefault();
        const businessId = location.state.business_id;
        const productBulkUploadURL = process.env.REACT_APP_SERVICE_URI + "products/bulk_upload?" + businessId;

        setIsFetching(true);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            postProductBulkUpload(productBulkUploadURL, businessId, getConfig(accessToken));        
        });
    }

    const handleReset = () => {
        setSelectedFeatures([]);
        setProductBulkFile(null);
        setResponseData({ success: [], errors: [] });
    }

    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : null}
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                }}
            >
                <CircularProgress />
            </Modal>
            {loaded?
            <div className='ag-grid-size'>
                <div className='ms-4'>
                    <form onSubmit={handleProductBulkUpload} onReset={handleReset}>
                        <h3 style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>Product Bulk Upload</h3>

                        <label htmlFor='businessDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Upload File</label>
                        <label htmlFor='businessDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Required)</label>

                        <div style={{ height: '20px', width: '100%' }}></div>

                        <div id="productBulkUpload">
                            <BorderedSection title={'Upload Products'}>
                                <div style={{ height: '20px', width: '100%' }}></div>
                                <ProductBulkUploadOption  productBulkFile={productBulkFile}  setProductBulkFile={setProductBulkFile} />
                            </BorderedSection>
                        </div>

                        {/* Success Table */}
                        {responseData.success && responseData.success.length > 0 && (
                        <div>
                            <h3>Success</h3>
                            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
                            <thead>
                                <tr style={{ backgroundColor: "#d4edda" }}>
                                <th>SL. No</th>
                                <th>Product Name</th>
                                <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {responseData.success.map((item, index) => (
                                <tr key={item.id || index} style={{ border: "1px solid rgb(211, 211, 211)" }}> {/* Fallback to index if id is missing */}
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.message}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        )}

                        {responseData.errors && responseData.errors.length > 0 && (
                        <div>
                            <h3>Errors</h3>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr style={{ backgroundColor: "#f8d7da" }}>
                                <th>SL. No</th>
                                <th>Product Name</th>
                                <th>Error Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {responseData.errors.map((item, index) => (
                                <tr key={item.id || `error-${index}`} style={{ border: "1px solid rgb(211, 211, 211)" }}> {/* Handle id gracefully */}
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.error}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        )}

                        <div className="button-container">
                            <button type="submit" className="btn btn-primary" disabled={!productBulkFile}>Upload</button>
                            <button type="reset" className="btn-clear">Clear</button>
                        </div>
                    </form>
                </div>
            </div>: null}
        </>
    )
}

export default ProductBulkUpload;