import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import PolicyIcon from '@mui/icons-material/Policy';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from 'react-router-dom';

const iconAndTextColor = '#FFFFFF';
const backgroundColor = 'linear-gradient(to top left, #002D70, #00569e)';

const termsOfService = "/terms-of-service-mobile";
const privacyPolicy = "/privacy-policy-mobile";
const dataPolicy = "/data-policy-mobile";
const disclaimer = "/disclaimer-mobile";
const appPermissions = "/app-permissions-mobile";

const FooterMobile = () => {

    const navigate = useNavigate();

    const handleTermsOfServiceClick = () => {
        navigate(termsOfService);
    };

    const handlePrivacyPolicyClick = () => {
        navigate(privacyPolicy);
    };
    const handleDataPolicyClick = () => {
        navigate(dataPolicy);
    };
    const handleDisclaimerClick = () => {
        navigate(disclaimer);
    };
    const handleAppPermissionsClick = () => {
        navigate(appPermissions);
    };


  return (
    <Box sx={{ pb: 8 }}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 , paddingTop: 1, paddingBottom:1, background: backgroundColor }} elevation={3}>
        <BottomNavigation showLabels sx={{background: backgroundColor}}>
          <BottomNavigationAction
            label="Terms Of Service"
            icon={<PolicyIcon style={{ color: iconAndTextColor }} />}
            onClick={handleTermsOfServiceClick}
            sx={{ color: iconAndTextColor, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <ListItemText sx={{ color: iconAndTextColor, textAlign: 'center' }} primary="Terms Of Service" />
          </BottomNavigationAction>
          <BottomNavigationAction
            label="Privacy Policy"
            icon={<SecurityIcon style={{ color: iconAndTextColor }} />}
            onClick={handlePrivacyPolicyClick}
            sx={{ color: iconAndTextColor, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <ListItemText sx={{ color: iconAndTextColor, textAlign: 'center' }} primary="Privacy Policy" />
          </BottomNavigationAction>
          <BottomNavigationAction
            label="Data Policy"
            icon={<StorageIcon style={{ color: iconAndTextColor }} />}
            onClick={handleDataPolicyClick}
            sx={{ color: iconAndTextColor, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <ListItemText sx={{ color: iconAndTextColor, textAlign: 'center' }} primary="Data Policy" />
          </BottomNavigationAction>
          <BottomNavigationAction
            label="Disclaimer"
            icon={<InfoOutlinedIcon style={{ color: iconAndTextColor }} />}
            onClick={handleDisclaimerClick}
            sx={{ color: iconAndTextColor, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <ListItemText sx={{ color: iconAndTextColor, textAlign: 'center' }} primary="Permissions" />
          </BottomNavigationAction>
          <BottomNavigationAction
            label="App Permissions"
            icon={<SettingsIcon style={{ color: iconAndTextColor }} />}
            onClick={handleAppPermissionsClick}
            sx={{ color: iconAndTextColor, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <ListItemText sx={{ color: iconAndTextColor, textAlign: 'center' }} primary="App Permissions" />
          </BottomNavigationAction>
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default FooterMobile;
