import React, { useState, useCallback, useMemo } from 'react';
import axios from "axios";
import {GridReadyEvent, FirstDataRenderedEvent} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ModifySelectedFeatureButton from './ModifySelectedFeatureButton';
import DeleteSelectedFeatureButton from './DeleteSelectedFeatureButton';
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import '../../../pages/Common/styles.css';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { store } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';

function ViewFeatures(props: any) {

    const [isFetching, setIsFetching] = useState(false);
    const [features, setFeatures] = useState([]);

    const storeData = store.getState();

    let navigate = useNavigate();
    
    const viewFeaturesURL = process.env.REACT_APP_SERVICE_URI + "features/";

    const containerStyle = useMemo(() => ({ }), []);
    const gridStyle = useMemo(() => ({ }), []);

    const [columnDefs, setColumnDefs] = useState([
        {headerName: 'Feature Name', field: 'title', maxWidth: 600, flex:3.5, resizable: true, filter: true},
        {
            field: 'modify',
            headerName: '',
            resizable: true,
            flex: 1,
            cellRenderer: ModifySelectedFeatureButton
        },
        {field: 'delete', headerName: '', resizable: true, flex: 1, cellRenderer: DeleteSelectedFeatureButton}
      ]);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            axios.get(viewFeaturesURL, getConfig(accessToken))
            .then((response) => {
                setFeatures(response.data);
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        })
      }, []);

    return (
        <>
            <SuperAdminNavbar />
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                }}
            >
                <CircularProgress />
            </Modal>
            <div className='ag-grid-size' >
				<div style={containerStyle}>
					<div style={gridStyle} className="ag-theme-alpine">
						<AgGridReact
							columnDefs={columnDefs}
							rowData={features}
							animateRows={true}
							domLayout='autoHeight'
							onGridReady={onGridReady}
						>
                  		</AgGridReact>
					</div>
				</div>
			</div>
        </>
    )
}

export default ViewFeatures;