import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { APP_NAME }  from '../../../pages/Common/GlobalValues';
import './SuperAdminNavbar.css';
import {
	useMediaQuery,
  } from "@mui/material";


// function Navbar() {
//   const [click, setClick] = useState(false);
//   const [dropdown, setDropdown] = useState(false);

//   const handleClick = () => setClick(!click);
//   const closeMobileMenu = () => setClick(false);

//   const onMouseEnter = () => {
//     if (window.innerWidth < 960) {
//       setDropdown(false);
//     } else {
//       setDropdown(true);
//     }
//   };

//   const onMouseLeave = () => {
//     if (window.innerWidth < 960) {
//       setDropdown(false);
//     } else {
//       setDropdown(false);
//     }
//   };

//   return (
//     <>
//       <nav className='navbar'>
//         <Link to='nixconnect/' className='navbar-logo' onClick={closeMobileMenu}>
//           Nix Connect
//           <i className='fab fa-firstdraft' />
//         </Link>
//         <div className='menu-icon' onClick={handleClick}>
//           <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
//         </div>
//         <ul className={click ? 'nav-menu active' : 'nav-menu'}>
//           <li className='nav-item'>
//             <Link to='nixconnect/' className='nav-links' onClick={closeMobileMenu}>
//               Features
//             </Link>
//           </li>
//           <li
//             className='nav-item'
//             onMouseEnter={onMouseEnter}
//             onMouseLeave={onMouseLeave}
//           >
//             <Link
//               to='/services'
//               className='nav-links'
//               onClick={closeMobileMenu}
//             >
//               Business <i className='fas fa-caret-down' />
//             </Link>
//             {dropdown && <Dropdown />}
//           </li>
//           <li className='nav-item'>
//             <Link
//               to='/products'
//               className='nav-links'
//               onClick={closeMobileMenu}
//             >
//               Users
//             </Link>
//           </li>
//           <li className='nav-item'>
//             <Link
//               to='/contact-us'
//               className='nav-links'
//               onClick={closeMobileMenu}
//             >
//               Sales
//             </Link>
//           </li>
//           <li>
//             <Link
//               to='/sign-up'
//               className='nav-links-mobile'
//               onClick={closeMobileMenu}
//             >
//               Sign Up
//             </Link>
//           </li>
//         </ul>
//         <Button />
//       </nav>
//     </>
//   );
// }

// export default Navbar;



import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {store} from '../../../redux/store';
import Logout from '../../Common/Logout';
import UserProfile from '../../Common/UserProfile';
import theme from "../../../themes/theme";

function SuperAdminNavbar() {
    // const [showBusiness, setShowBusiness] = useState(false);
    // const [showUsers, setShowUsers] = useState(false);
    // const [showSales, setShowSales] = useState(false);
    const userSlice = store.getState();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Navbar collapseOnSelect={true} sticky="top" expand="lg" className="text-white bg-body-tertiary navbar" variant='dark'>
      	<Container>
			{/* <Navbar.Brand href="/admin/home" className='text-white'>{APP_NAME}</Navbar.Brand> */}
			<Navbar.Brand href="/admin/home">
			<img 
				src={process.env.PUBLIC_URL + '/resources/nixel_website_logo.png'} 
				alt="Company Logo" 
				style={{ maxWidth: isMobile ? 56 : 64 , marginRight: 18, cursor: 'pointer' }}
				/>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="me-auto nav-item text-light">
					<NavDropdown title="Features" className='text-white' id="collasible-nav-dropdown" >
						<NavDropdown.Item eventKey="1" as={Link} to="/admin/create-feature">Create Feature</NavDropdown.Item>
						<NavDropdown.Item eventKey="2" as={Link} to="/admin/view-modify-and-delete-feature">
							View, Modify and Delete
						</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown title="Enrolments" className='text-white' id="collasible-nav-dropdown" 
					// show={showBusiness} onMouseEnter={() => setShowBusiness(prevState => !prevState)} onMouseLeave={() => setShowBusiness(prevState => !prevState)}
					>
						<NavDropdown.Item eventKey="3" as={Link} to="/admin/manage-enrolment">View</NavDropdown.Item>
					</NavDropdown>


					<NavDropdown title="Business" className='text-white' id="collasible-nav-dropdown" 
					// show={showBusiness} onMouseEnter={() => setShowBusiness(prevState => !prevState)} onMouseLeave={() => setShowBusiness(prevState => !prevState)}
					>
						<NavDropdown.Item eventKey="4" as={Link} to="/admin/create-business">Create Business</NavDropdown.Item>
						<NavDropdown.Item eventKey="5" as={Link} to="/admin/view-modify-and-delete-business">
							View, Modify and Delete
						</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown title="Users" id="collasible-nav-dropdown" 
						// show={showUsers} onMouseEnter={() => setShowUsers(prevState => !prevState)} onMouseLeave={() => setShowUsers(prevState => !prevState)}
					>
						<NavDropdown.Item eventKey="6" as={Link} to="/admin/invite-user">Invite User</NavDropdown.Item>
						<NavDropdown.Item eventKey="7" as={Link} to="/admin/pending-users">Pending Users</NavDropdown.Item>
						<NavDropdown.Item eventKey="8" as={Link} to="/admin/active-users">Active Users</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown title="Sales" id="collasible-nav-dropdown" 
						// show={showSales} onMouseEnter={() => setShowSales(prevState => !prevState)} onMouseLeave={() => setShowSales(prevState => !prevState)}
					>
						<NavDropdown.Item eventKey="9" as={Link} to="/admin/view-sales">View Sales</NavDropdown.Item>
					</NavDropdown>
				</Nav>
				<UserProfile />
			</Navbar.Collapse>
      	</Container>
    </Navbar>
	);
}

export default SuperAdminNavbar;