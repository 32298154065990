import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './styles.css';
import CircularProgress from "@mui/material/CircularProgress";
import theme from "../../themes/theme";
import { 
    Modal,
    Container,
    AppBar,
    Grid,
    Paper,
    Box,
    ThemeProvider,
    Toolbar,
    Typography,
    Stack,
    TextField,
    Button,
    Menu,
    MenuItem,
    useMediaQuery,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { BusinessDetails } from './BusinessDetails';
import { BankDetails } from './BankDetails';
import { TaxDetails } from './TaxDetails';
import { RegistrationDetails } from './RegistrationDetails';
import { APP_NAME, APP_TAG_LINE } from './HomePageInformation';
import NavBarGeneral from './NavBarGeneral';
import Footer from './Footer';

function DeleteYourBusiness() {
    const navigate = useNavigate();
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []); 

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const deleteYourBusinessURL = process.env.REACT_APP_SERVICE_URI + "enrol_your_business/delete-enrolment";
    const loginURL = "/login";
    const homeURL = "/home";
    const enrolNow = "/enroll-your-business";

    const [id, setId] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');

    const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const handleHomeClick = () => {
      handleMenuClose();
      navigate(homeURL);
    };

    const handleEnrolNowClick = () => {
        handleMenuClose();
        navigate(enrolNow);
    };

    const handleSignInClick = () => {
        handleMenuClose();
        navigate(loginURL);
      };

    
    const handleCaptchaChange = () => {
        setIsCaptchaChecked(!isCaptchaChecked);
      };

    const handleBusinessDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    // Update the corresponding state based on the field
        switch (item) {
            case 'id':
                setId(event.target.value);
                break;
            case 'business_name':
                setBusinessName(event.target.value);
                break;
            case 'email':
                setEmail(event.target.value);
                break;
            case 'mobile':
                setMobile(event.target.value);
                break;
            default:
            break;
        }   
    };  

    const handleEnrollYourBusiness = (event: any) => {
        event.preventDefault();

        if (isCaptchaChecked) {
            setIsFetching(true);
            axios.delete(`${deleteYourBusinessURL}/${id}/`, {
                params: {
                    business_name: businessName,
                    email: email,
                    mobile: mobile
                }
            })
            .then((response) => {
                const message = response.data.message;
                const enrolId = response.data.enrol_id;
                if (message.toUpperCase() === 'Success'.toUpperCase()) {
                    alert(`Your Business Enrolment has been successfully deleted.\nDeleted Enrollment ID is ${enrolId}`);
                    setIsFetching(prevState => !prevState)
                    navigate("/");
                } else {
                    alert('We apologize for the inconvenience. No matching details were found.\nPlease review the provided information or reach out to NiXel Systems for assistance.');
                }
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('We apologize for the inconvenience. No matching details were found.\nPlease review the provided information or reach out to NiXel Systems for assistance.');
                navigate("/");
            }); 
          } else {
            alert('Please confirm that you are not a robot before submitting.');
          }
    }

    const gridContainerStyle: React.CSSProperties = {
        padding: '16px',
        height: '100%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: isSmallScreen ? '112px' : '136px',
        marginBottom: '40px',
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
    };

    const bodyContainerStyle: React.CSSProperties = {
        backgroundColor: 'linear-gradient(to bottom right, #FAF8F0, #FAF8F0)',
        padding: '12px', 
    };

    const cardStyle: React.CSSProperties = {
        paddingTop: '16px', 
        paddingBottom: '16px',
        paddingRight: '8px', 
        paddingLeft: '8px', 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const titleTextStyle: React.CSSProperties = {
        textAlign: 'center',
        marginTop: '0px', 
        color: '#004AAD', 
        fontSize: '36px',
        fontFamily: 'Poppins',
        fontWeight: 'regular',
        fontStyle: 'normal',
        letterSpacing: '1.5px', 
        lineHeight: 1.2,
    };

      const handleNavBarItemClick = (key: String, value:String) => {
        if (key === 'menu_0') {
            handleHomeClick();
        } else if (key === 'menu_1') {
            handleEnrolNowClick();
        } else if (key === 'menu_2') {
            handleSignInClick();
        }
    };

    const navBarProps = {
        items: ['Home','Enrol Now', 'Sign In'],
        onItemClick: handleNavBarItemClick,
    };  

    return (
        <ThemeProvider theme={theme}>
            <NavBarGeneral { ...navBarProps } />
                <Container>
                    <form onSubmit={handleEnrollYourBusiness}>
                        <Paper elevation={2} style={{ ...gridContainerStyle, display: 'flex', flexDirection: 'column', borderRadius: '8px' }}>
                            <Grid container spacing={2} style={{ flex: 1 }}>
                                <Grid item xs={12}  style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Paper elevation={4} style={{ ...bodyContainerStyle, flex: 1, borderRadius: '8px', backgroundColor: '#004AAD' }}>
                                        <Stack direction="column" spacing={0} style={{ ...cardStyle }}>
                                            <Typography gutterBottom variant="h4" style={{ ...titleTextStyle, color: '#FFFFFF', fontSize: '32px' }}>
                                            Delete Your Business Enrollment 
                                            </Typography>
                                        </Stack>
                                    </Paper>
                                </Grid>       
                                <Grid item xs={12}  >
                                    <Modal
                                        open={isFetching}
                                        style={{
                                            position: 'absolute',
                                            height: 50,
                                            width: 100,
                                            margin: 'auto'
                                        }} >
                                        <CircularProgress />
                                    </Modal> 
                                    <Stack id="enrol-business" direction="column" spacing={0} style={{padding: '0px'}}>
                                        <Box>
                                            <label htmlFor='businessDetails' style={{ fontSize: 20, fontWeight: 'bold', marginTop: 25 }}>Business Details</label>
                                            <label htmlFor='businessDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginTop: 25}}> (Required)</label>
                                        </Box>
                                        <Box id="outlined-controlled" className="form-group-box row">
                                            <TextField id="outlined-controlled" className="form-control me-3 col" label="Enrolment Id" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "id")} required variant="outlined" />

                                            <TextField 
                                                id="outlined-controlled" 
                                                className="form-control me-3 col" 
                                                label="Business Name" 
                                                InputLabelProps={{
                                                    style: { color: 'grey' },
                                                }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "business_name")} 
                                                required 
                                                variant="outlined" />
                                        </Box> 

                
                                        <Box id="outlined-controlled" className="form-group-box row">
                                            <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Email" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "email")} required variant="outlined" />
                                            <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Mobile" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "mobile")} required variant="outlined" />
                                        </Box>
                                        
                                        <Box id='captchCheckBox' style={{ padding: '10px', border: '0px solid #ddd', borderRadius: '5px', display: 'inline-block', marginTop: '20px' }}>
                                            <label style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>
                                                <input
                                                    type="checkbox"
                                                    checked={isCaptchaChecked}
                                                    onChange={handleCaptchaChange}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                <span style={{ fontSize: '20px' }}>I'm not a robot</span>
                                            </label>
                                        </Box>       
                                        <Box id="button-container" className="button-container" style={{ flex: 1, justifyContent: 'center', marginTop: '25px', marginBottom: '25px', paddingBottom: '25px' }} >
                                            <Button  type="submit" variant="contained" color="success" style={{ width: '50%', marginRight: '10px', borderRadius: '36px' }} size="large">
                                                Delete
                                            </Button>
                                            <Button type="reset" variant="outlined" color="error" style={{ width: '50%', marginLeft: '10px', borderRadius: '36px' }} size="large">
                                                Clear
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                    
                        </Paper>
                    </form>
                </Container>
            <Footer/>
        </ThemeProvider>
    )
}

export default DeleteYourBusiness;