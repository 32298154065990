import React, { useEffect, useState } from 'react';
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { store } from '../../../redux/store';

function CreateFeature() {

    const featuresURL = process.env.REACT_APP_SERVICE_URI + "features/";

    const [isLoading, setIsLoading] = useState(false);

    const [position, setPosition] = useState(0);
    const [resourceId, setResourceId] = useState('');
    const [title, setTitle] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [textColor, setTextColor] = useState('');
    const [textSize, setTextSize] = useState(0);
    const [iconName, setIconName] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isAttachedToBusiness, setAttachedToBusiness] = useState(false);

    const navigate = useNavigate();

    const storeData = store.getState();

    const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosition(Number(event.target.value));
    }

    const handleResourceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResourceId(event.target.value);
    }

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const handleBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBackgroundColor(event.target.value);
    }

    const handleTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextColor(event.target.value);
    }

    const handleTextSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSize(Number(event.target.value));
    }

    const handleIconNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIconName(event.target.value);
    }

    const handleIsVisibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsVisible(event.target.checked);
    }

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        validateAccessToken()
    }, [])

    const createFeature = (event: any) => {
        setIsLoading(true);
        event.preventDefault();

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;

            axios.post(featuresURL, {
                position: position,
                resource_id: resourceId,
                title: title,
                background_color: backgroundColor,
                text_color: textColor,
                text_size: textSize,
                icon_name: iconName,
                is_visible: isVisible,
                is_attached_to_business: isAttachedToBusiness
            }, getConfig(accessToken))
            .then((response) => {
                const message = response.data.message;
                if (message.toUpperCase() === 'Success'.toUpperCase()) {
                    setIsLoading(false);
                    alert('Successfully created new feature');
                    window.location.reload();
                } else {
                    setIsLoading(false);
                    alert('Unable to register. Please try after some time.');
                    navigate("/admin/create-business");
                }
                window.location.reload();
            })
            .catch((error) => {
                setIsLoading(false);
                alert("This record already exists!");
                navigate("/admin/create-feature");
            });
        })
    }

    return (
        <>
            <SuperAdminNavbar />
            <div className='ms-4' style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>
                <form onSubmit={createFeature}>
                    <h3 style={{display: 'flex', justifyContent: 'center'}}>Add New Feature</h3>
                    <div className="form-group my-2 row"  >
                        <TextField type="number" className="form-control me-3 col" id="inputPosition" label="Position" onChange={handlePositionChange} required variant="outlined" />
                        <TextField type="text" className="form-control col" id="inputResourceId" label="Resource Id"  onChange={handleResourceIdChange} required variant="outlined" />
                    </div>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="inputTitle" label="Title" onChange={handleTitleChange} required variant="outlined" />
                        <TextField type="text" className="form-control col" id="inputBackgroundColor" label="Background Color" onChange={handleBackgroundColorChange} required variant="outlined" />
                    </div>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="inputTextColor" label="Text Color" onChange={handleTextColorChange} required variant="outlined" />
                        <TextField type="number" className="form-control me-3 col" id="inputTextSize" label="Text Size" onChange={handleTextSizeChange} required variant="outlined" />
                        <TextField type="text" className="form-control col" id="iconName" label="Icon Name" onChange={handleIconNameChange} required variant="outlined" />
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="isVisible" onChange={handleIsVisibleChange}/>
                        <label className="form-check-label" htmlFor="isVisible">Is Visible</label>
                    </div>

                    <Modal
                        open={isLoading}
                        style={{
                            position: 'absolute',
                            height: 50,
                            width: 100,
                            margin: 'auto'
                        }}
                    >
                        <CircularProgress />
                    </Modal>
                    <div className="button-container">
                        <button type="submit" className="btn btn-primary">Create</button>
                        <button type="reset" className="btn-clear">Clear</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateFeature;