import React from 'react';
import { Link } from 'react-router-dom';
import { store } from '../../../redux/store';
import { salesSlice } from '../../../redux/salesSlice';

export default (props: any) => {

  const storeData = store.getState();

  const updateSalesSlice = () => {
    store.dispatch(salesSlice.actions.updateSelectedOrder(props.data.order_id));
  }

  return (
    <span>
      <Link to={"/" + storeData.user.role.split("_")[1].toLowerCase() +"/view-selected-order/"}
          onClick={updateSalesSlice}
      >
      View More
      </Link>
    </span>
  );
};