import React, {useEffect} from 'react';
import ViewSales from '../../components/SuperAdminView/ViewSales/ViewSales';
import { didLogout } from '../../components/Common/Logout';
import { useNavigate } from 'react-router-dom';

function BusinessMemberHomepage() {

    const navigate = useNavigate();
    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
    }, []);
    
    return (
        <ViewSales role={'BUSINESS_MEMBER'} />
    )
}

export default BusinessMemberHomepage;