import { useState } from "react";
import { store } from "../redux/store";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedAdminRoute = () => {
    const userSlice = store.getState();

    const [admin, setAdmin] = useState(userSlice.user.role === 'SUPER_ADMIN' ? true : null);

    return admin ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedAdminRoute;