import { useState } from "react";
import { store } from "../redux/store";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedOwnerRoute = () => {
    const userSlice = store.getState();

    const [owner, setOwner] = useState(userSlice.user.role === 'BUSINESS_OWNER' ? true : null);

    return owner ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedOwnerRoute;