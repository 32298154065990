import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from "axios";
import { SelectedBusinessSales } from './SelectedBusinessSales';
import { AgGridReact } from 'ag-grid-react';
import {GridReadyEvent} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import ViewSelectedBusinessMemberSalesButton from './ViewSelectedBusinessMemberSalesButton';
import {useLocation} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import { useNavigate} from "react-router-dom";
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import BusinessMemberNavbar from '../../BusinessMemberView/Navigation/BusinessMemberNavbar';
import { store } from '../../../redux/store';
import '../../../pages/Common/styles.css';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';

export default (props: any) => {

    const [isFetching, setIsFetching] = useState(false);
    const [selectedBusinessSalesData, setSelectedBusinessSalesData] = useState<SelectedBusinessSales>();

    const storeData = store.getState();
    let navigate = useNavigate();

    const containerStyle = useMemo(() => ({  }), []);
    const gridStyle = useMemo(() => ({  }), []);

    const [columnDefs, setColumnDefs] = useState([
        {headerName: 'Business Member Name', field: 'business_member_name', maxWidth: 600, flex: 2, filter: true},
        {headerName: 'Total Sales', field: 'total_sales', maxWidth: 600, flex: 2, filter: true, cellRenderer: (params: any) => {return params.data.currency_symbol + params.data.total_sales + " " + params.data.currency_code}},
        {field: '', flex: 1.5, cellRenderer: ViewSelectedBusinessMemberSalesButton},
      ]);

    const getSelectedBusinessSales = async (viewSelectedBusinessSalesURL: string, config: any) => {
        try {
            const response = await axios.get(viewSelectedBusinessSalesURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const businessProfileId = storeData.sales.selectedBusiness;
            const viewSelectedBusinessSalesURL = process.env.REACT_APP_SERVICE_URI + "sales/report/" + businessProfileId;

            getSelectedBusinessSales(viewSelectedBusinessSalesURL, getConfig(accessToken))
            .then((data) => {
                setSelectedBusinessSalesData(data);
            })
            .catch((error) => {
                alert('Loading failed!');
            });
        })
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        setIsFetching(false);
    }, []);

    return (
        <>             
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : <BusinessMemberNavbar />}
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
                
            {selectedBusinessSalesData ?
                <div className='ag-grid-size'>
                    <div style={containerStyle}>
                        <h5>Business Name: {selectedBusinessSalesData.company_business_name}</h5>
                        <h6>Owner Name: {selectedBusinessSalesData.business_owner_name}</h6>
                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                                // ref={gridRef} // Ref for accessing Grid's API
                                columnDefs={columnDefs}
                                rowData={selectedBusinessSalesData.business_member_sales}
                                animateRows={true}
                                domLayout='autoHeight'
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                                }
                                onGridReady={onGridReady}
                            >
                            </AgGridReact>
                        </div>
                        <br />
                        <u style={{color:'#0D6EFD', cursor: 'pointer'}} onClick={() => navigate(-1)}>Back</u>
                    </div>
                </div>
            :null}
        </>
    );
};