import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import { store } from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';


export default (props: any) => {
  	const [open, setOpen] = useState(false);
  	const [text, setText] = useState('');

	const storeData = store.getState();
	const navigate = useNavigate();

	const deleteFeatureURL = process.env.REACT_APP_SERVICE_URI + "features/" + props.data.id + "/";

	const handleClickOpen = (event: any) => {
		event.preventDefault();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setText(value);
	}

	const handleDelete = () => {
		if(didLogout()) {
			navigate("/");
		}
		if (text.toLowerCase() === 'delete') {
			validateAccessToken().then((fetchedAccessToken) => {
				const accessToken = fetchedAccessToken || storeData.user.access_token as string;
				axios.delete(deleteFeatureURL, getConfig(accessToken))
				.then((response) => {
					alert('Deleted feature successfully')
				})
				.catch((error) => {
					alert('Sorry unable to delete feature at the moment');
				});
			})
		}
	}

	return (
		<span
			style={{color:'#0D6EFD', cursor: 'not-allowed'}}
		>
		<u
			// onClick={handleClickOpen}
			style={{color:'#0D6EFD', pointerEvents: 'none'}}
		>
			Delete
		</u>

		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Warning</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Please type Delete if you want to feature feature <i>{props.data.company_business_name}</i>
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id="delete"
				label="Type here"
				type="text"
				fullWidth
				variant="standard"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
			/>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose}>Cancel</Button>
			<Button onClick={handleDelete}>Delete</Button>
			</DialogActions>
		</Dialog>
		</span>
	);
};