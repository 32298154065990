import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

interface CustomTypographyOptions extends TypographyOptions {
  fontStyleItalic?: string;
}

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Open Sans, Merriweather, Arial, sans-serif',
    fontWeight: {
      regular: 400,
      bold: 700,
      light: 300,
    },
    fontStyle: {
      italic: 'italic',
    },
  } as CustomTypographyOptions,
});

export default theme;