import React from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Logout from './Logout';
import { store } from '../../redux/store';

function UserProfile() {
    const userSlice = store.getState();

    return (
        <div className='col' style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Tooltip title={
                <>
                    {userSlice.user.name}<br />
                    {userSlice.user.role ? userSlice.user.role.split("_")[1].charAt(0).toUpperCase() + userSlice.user.role.split("_")[1].slice(1).toLowerCase(): null}<br />
                    {userSlice.user.email}
                </>
            }>
                <Avatar alt={userSlice.user.name} src="/" style={{marginRight: 10}}/>
            </Tooltip>
            <Logout />
        </div>
    )
}

export default UserProfile;