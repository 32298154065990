import React, { useState, useEffect } from 'react';
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { store } from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';

function ModifySelectedFeature() {
    const [isFetching, setIsFetching] = useState(false);

    const [position, setPosition] = useState<number>(0);
    const [resourceId, setResourceId] = useState('');
    const [title, setTitle] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [textColor, setTextColor] = useState('');
    const [textSize, setTextSize] = useState<number>(0);
    const [iconName, setIconName] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isAttachedToBusiness, setIsAttachedToBusiness] = useState(false);

    const location = useLocation();

    const storeData = store.getState();

    const navigate = useNavigate();

    const getFeature = async (getFeatureURL: string, config: any) => {
        try {
            const response = await axios.get(getFeatureURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const featureId = location.state.feature_id;
            const getFeatureURL = process.env.REACT_APP_SERVICE_URI + "features/" + featureId;

            getFeature(getFeatureURL, getConfig(accessToken))
            .then((data) => {
                setPosition(data.position);
                setResourceId(data.resource_id);
                setTitle(data.title);
                setBackgroundColor(data.background_color);
                setTextColor(data.text_color);
                setTextSize(data.text_size);
                setIconName(data.icon_name);
                setIsVisible(data.is_visible);
                setIsAttachedToBusiness(data.is_attached_to_business)
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        })
    },[])

    const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosition(Number(event.target.value));
    }

    const handleResourceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResourceId(event.target.value);
    }

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const handleBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBackgroundColor(event.target.value);
    }

    const handleTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextColor(event.target.value);
    }

    const handleTextSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSize(Number(event.target.value));
    }

    const handleIconNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIconName(event.target.value);
    }

    const handleIsVisibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsVisible(event.target.checked);
    }

    const modifyFeature = (event: any) => {
        event.preventDefault();
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const featureId = location.state.feature_id;
            const modifyFeatureURL = process.env.REACT_APP_SERVICE_URI + "features/" + featureId + "/";

            axios.patch(modifyFeatureURL, {
                position: position,
                resource_id: resourceId,
                title: title,
                background_color: backgroundColor,
                text_color: textColor,
                text_size: textSize,
                icon_name: iconName,
                is_visible: isVisible,
                is_attached_to_business: isAttachedToBusiness
            }, getConfig(accessToken))
            .then((response) => {
                const message = response.data.message;
                setIsFetching(false);
                if (message.toUpperCase() === 'Success'.toUpperCase()) {
                    alert('Success');
                    navigate("/admin/view-modify-and-delete-feature");
                } else {
                    alert('Unable to modify feature. Please try after some time.');
                    navigate("/admin/view-modify-and-delete-feature");
                }
            })
            .catch((error) => {
                setIsFetching(false);
                alert("We're Sorry. Unable to modify feature right now!");
                return;
            });
        })
    }

    const handleReset = () => {
        setPosition(0);
        setResourceId('');
        setTitle('');
        setBackgroundColor('');
        setTextColor('');
        setTextSize(0);
        setIconName('');
        setIsVisible(false);
        setIsAttachedToBusiness(false);
    }

    return (
        <>
            <SuperAdminNavbar />
            <div className='ms-4' style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>
                <form onSubmit={modifyFeature} onReset={handleReset}>
                    <h3 style={{display: 'flex', justifyContent: 'center'}}>Modify Feature</h3>
                    <div className="form-group my-2 row" >
                        <TextField type="number" className="form-control me-3 col" id="inputPosition" label="Position" value={position} onChange={handlePositionChange} required variant="outlined" />
                        <TextField type="text" className="form-control col" id="inputResourceId" label="Resource Id" value={resourceId} onChange={handleResourceIdChange} required variant="outlined" />
                    </div>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="inputTitle" label="Title" value={title} onChange={handleTitleChange} required variant="outlined" />
                        <TextField type="text" className="form-control col" id="inputBackgroundColor" label="Background Color" value={backgroundColor} onChange={handleBackgroundColorChange} required variant="outlined" />
                    </div>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="inputTextColor" label="Text Color" value={textColor} onChange={handleTextColorChange} required variant="outlined" />
                        <TextField type="number" className="form-control me-3 col" id="inputTextSize" label="Text Size" value={textSize} onChange={handleTextSizeChange} required variant="outlined" />
                        <TextField type="text" className="form-control col" id="iconName" label="Icon Name" value={iconName} onChange={handleIconNameChange} required variant="outlined" />
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="isVisible" checked={isVisible} onChange={handleIsVisibleChange}/>
                        <label className="form-check-label" htmlFor="isVisible">Is Visible</label>
                    </div>

                    <Modal
                        open={isFetching}
                        style={{
                            position: 'absolute',
                            height: 50,
                            width: 100,
                            margin: 'auto'
                        }}
                    >
                        <CircularProgress />
                    </Modal>

                    <div className="button-container">
                        <button type="submit" className="btn btn-primary" >Modify</button>
                        <button type="reset" className="btn-clear">Clear</button>
                    </div>
                    
                </form>
            </div>
        </>
    )
}

export default ModifySelectedFeature;