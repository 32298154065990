import {
  CONTACT_PHONE, 
  CONTACT_EMAIL, 
  TERMS_OF_SERVICE,
  POLICY_LAST_UPDATED_DATE } from '../GlobalValues';

const privacyPolicy = [
    {
      section: 'Nix Connect App - Privacy Policy',
      data: [
        { key: 'Effective Date', value: `${POLICY_LAST_UPDATED_DATE}` },
        {
          key: 'Introduction',
          value:
            'Welcome to Nix Connect! Nix Connect App, provided by NiXel Systems Pty Ltd (referred to as "the Company"). This Privacy Policy outlines how we collect, use, and protect your data when you use our Nix Connect Application. It is crucial to read and understand this policy to make informed decisions about your data and privacy.',
        },
        {
          key: 'Information We Collect',
          value: [
            'User Provided Business Information: Business Name, Business Website URL, Address (City, State, Country, Postcode), Business Email, Business Mobile, Business Landline, Bank Details like Name, Branch, Address etc, Pay Id Information, Tax Details, Business Registration Details, Additional Company Information if Any',
            'Uploaded Content: Any content, including images, that you upload to the app may be utilized on our Nix Connect website and on your online store, for marketing purposes, or for similar functionalities.',
            'User Provided Personal Information: Email ID, User Name, Password, Mobile Number, Alternate Email ID, Landline phone Number, Address (City, State, Country, Postcode)',
            'Automatically Collected Information: We use Google Firebase Analytics to gather App usage and Analytics reports, including device information and usage data.',
          ],
        },
        {
          key: 'How We Use Your Information',
          value: ['To Provide and Maintain the App', 'To Personalize User Experience', 'To Communicate'],
        },
        {
          key: 'Data Storage and Security',
          value:
            'Your data is securely stored on protected cloud servers. Access to personal details is limited to the user, business owner, and product owner. Passwords are strictly confidential, known only to the user. We adhere to industry standards for password storage, utilizing robust encryption mechanisms for added security. In unexpected situations, like global hacking incidents, challenges may arise despite our robust security measures. Please be assured that we are dedicated to promptly notifying you as soon as we become aware of any security breach.',
        },
        {
          key: 'Responsibility and Liability',
          value:
            'NiXel Systems, including its product Nix Connect, is committed to providing a secure and reliable user experience. Please carefully review the following terms regarding responsibility and liability:',
          nestedValue: [
            {
              key: 'Responsibility',
              value: [
                'We take responsibility for implementing appropriate technical and organizational measures to safeguard user data.',
                'We are accountable for the proper functioning and performance of our services, ensuring they align with industry standards and user expectations.',
                'We strive to maintain a transparent and honest relationship with our users and authorized personnel, providing accurate information and promptly addressing any concerns.',
                'We won\'t entertain the sharing of credentials with individuals other than authorized personnel assigned to perform specific tasks.',
              ],
            },
            {
              key: 'Liability',
              value: [
                'While we endeavor to uphold the highest standards of security, NiXel Systems and Nix Connect assume no liability for incidents beyond our control, including global hacking events.',
                'Users are encouraged to take proactive measures to secure their accounts, such as utilizing strong passwords and staying informed about security best practices.',
                'NiXel Systems and Nix Connect are not liable for any direct or indirect damages, losses, or disruptions caused by external factors, including but not limited to unauthorized access, cyberattacks, or force majeure events.',
                'In case of unauthorized sharing of credentials leading to any issues or breaches, Nix Connect or NiXel Systems is not liable, and no compensations will be provided.',
              ],
            },
          ],
        },
        { key: 'Consent and Age Restrictions', value: 'By using Nix Connect, you affirm that you are of legal age to provide consent. If you are a parent or guardian, you must provide consent on behalf of a minor.' },
        { key: 'Data Sharing and Disclosure', value: 'We do not sell, rent, or lease your personal information. We may share information with your explicit consent or for legal compliance.' },
        {
          key: 'Your Rights and Choices',
          value: [
            'Access and Correction: You have the right to access and correct your personal information.',
            `Data Deletion: You can request the deletion of your account and associated data by contacting us at ${CONTACT_EMAIL}`,
          ],
        },
        {
          key: 'Updates to the Privacy Policy',
          value:
            'We may update this Privacy Policy periodically. The latest version will be available within the app (Non editable Hyperlink) or on our website. Significant changes will be communicated to users accordingly.',
        },
        { key: 'Contact Us', value: `If you have questions or concerns about our privacy practices, please contact us at ${CONTACT_EMAIL}.` },
        {
          key: 'Acknowledgment',
          value:
            'By using Nix Connect and associated services, users acknowledge and accept the terms outlined in this Privacy Policy. NiXel Systems reserves the right to update this statement as needed, and users are advised to periodically review it for any changes.',
        },
        { key: 'Policy Overview', value: `This Privacy Policy provides an overview of how we handle user data within the Nix Connect App. The ${POLICY_LAST_UPDATED_DATE} indicates the effective date of this policy.` },
      ],
    },
  ];
  
  export default privacyPolicy;
  