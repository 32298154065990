import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { 
    ThemeProvider,
    Modal, 
    Container,
    useMediaQuery,
    TextField,
 } from '@mui/material';
import theme from "../../themes/theme";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { APP_NAME, APP_TAG_LINE }  from './GlobalValues';
import { userSlice } from "../../redux/userSlice";
import './styles.css';
import {store} from '../../redux/store';
import { version } from '../../components/Common/Constants';
import NavBarGeneral from "./NavBarGeneral";
import Footer from './Footer';

function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const apiLoginURL = process.env.REACT_APP_SERVICE_URI + "auth/login/";
    const enrolNow = "/enroll-your-business";
    const homeUrl = "/home";
    const loginURL = "/login";

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
      };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }      

    const handleLogin = (event: any) => {
        setIsFetching(true);
        event.preventDefault();

        if (email != null && password != null) {
            axios.post(apiLoginURL, {
                email: email,
                password: password
            })
            .then((response) => {
                const data = response.data;
                const tokens = data.tokens;
                if (!tokens) {
                    alert('Unable to login. Please try after some time.');
                    return;
                }
                localStorage.clear();
                localStorage.setItem('logged-in', 'Yes');
                setIsFetching(false);
                store.dispatch(userSlice.actions.save(data))
                if (data.role === 'SUPER_ADMIN') {
                    navigate("/admin/home");
                } else if (data.role === 'BUSINESS_OWNER') {
                    navigate("/owner/home");
                } else if (data.role === 'BUSINESS_MEMBER') {
                    navigate("/member/home");
                }  
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Unable to login. Check your credentials');
                    setPassword('');
            });
        }
    }
    const gridContainerStyle: React.CSSProperties = {
        height: '100vh',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: isSmallScreen ? '112px' : '136px',
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
    };

    const handleHomeClick = () => {
        handleMenuClose();
        navigate(homeUrl);
      };

    const handleEnrolNowClick = () => {
        handleMenuClose();
        navigate(enrolNow);
    };

    const handleSignInClick = () => {
        handleMenuClose();
        navigate(loginURL);
    };

    const handleNavBarItemClick = (key: String, value:String) => {
        if (key === 'menu_0') {
            handleHomeClick();
        } else if (key === 'menu_1') {
            handleEnrolNowClick();
        } else if (key === 'menu_2') {
            handleSignInClick();
        }
    };
    
    const navBarProps = {
        items: ['Home','Enrol Now', ''],
        onItemClick: handleNavBarItemClick,
    };

    return (
        <ThemeProvider theme={theme}>
            <NavBarGeneral { ...navBarProps } />
            <Container >
                <Box className={`grid-container ${isSmallScreen ? 'margin-small-screen' : 'margin-large-screen'}`}>
                    <Box className={isSmallScreen ? "center-without-bg-image " : "center"} >
                            <div className="login-form">
                            <h1 style={{display: 'flex', justifyContent: 'center' }}>Sign In</h1>

                            <form onSubmit={handleLogin} >
                                <div className="form-group my-3 row" >
                                    <Box style={{ marginTop: '12px' }}>
                                        <TextField 
                                            type="email" 
                                            className="form-control col" 
                                            id="inputEmail" 
                                            label="Email" 
                                            aria-describedby="emailHelp" 
                                            InputLabelProps={{ style: { color: 'grey' }}}
                                            onChange={handleEmailChange} 
                                            required 
                                            variant="outlined" />
                                    </Box>
                                </div>
                                <div className="form-group my-3 row">
                                    <Box>
                                        <TextField 
                                            type="password" 
                                            className="form-control col" 
                                            id="inputPassword" 
                                            label="Password" 
                                            value={password} InputLabelProps={{ style: { color: 'grey' }}}
                                            onChange={handlePasswordChange} 
                                            required variant="outlined" />
                                    </Box>
                                </div>
                                <div className="form-group my-3 row">
                                    <Box display="flex" justifyContent="space-between" width="100%">
                                        <Link
                                            className="hyper-link-left-align"
                                            href={process.env.PUBLIC_URL + '/enroll-your-business'}
                                            underline="hover"
                                            style={{ width: '48%', fontWeight: 'bold' }} >
                                            Join Nix Connect
                                        </Link>
                                        <Link 
                                            className="hyper-link-right-align"
                                            href={process.env.PUBLIC_URL + '/forgot-password-email'}
                                            underline="hover"
                                            style={{ width: '48%' }} >
                                            Can't Get In?
                                        </Link>
                                    </Box>
                                </div>
                                <Modal
                                    open={isFetching}
                                    style={{
                                        position: 'absolute',
                                        height: 120,
                                        width: 120,
                                        margin: 'auto'
                                    }}
                                >
                                    <CircularProgress />
                                </Modal>
                                <center><button type="submit" className="btn btn-sign-in col" style={{ marginTop: '24px', borderRadius: '36px' }}>Sign In</button></center>
                                <center style={{ marginTop: '12px' }}>{APP_NAME} : {version}</center>
                            </form>
                        </div>
                    </Box>
                </Box>
            </Container>
            <Footer/>
        </ThemeProvider>
    )
}

export default Login;