export const APP_NAME = "Nix Connect";
export const APP_TAG_LINE = "Connecting the Business Dots";


export const ABOUT_NIX_CONNECT = "About Nix Connect";
export const ABOUT_NIX_CONNECT_DETAILS = `
  Welcome to Nix Connect - your trusted partner in simplifying and optimizing business management. 
  Our platform offers a unified mobile solution, bringing together every critical aspect of your business under one user-friendly app. 
  In a dynamic world where efficiency and seamless operations are non-negotiable, Nix Connect emerges as a beacon of innovation. 
  Designed to empower businesses of all sizes, our revolutionary platform is reshaping the landscape of business management.
`;

export const NIXEL_AND_NIX_CONNECT = `
Nix Connect, a flagship product from NiXel Systems, redefines efficiency in both B2B and B2C business operations. 
This all-in-one solution centralizes crucial functions like invoicing, inventory tracking, payment processing, 
logistics management, and more into a user-friendly mobile app. With a seamless blend of cutting-edge technology 
and user-centric design, Nix Connect offers businesses a secure and efficient platform for streamlined and 
well-coordinated operations.`;

export const WHY_NIX_CONNECT_TITLE = "Why Nix Connect for Your Business?";

export const homePageInformationData = [
    {
        key: `Comprehensive Integration:`,
        value: `Discover the power of comprehensive integration with Nix Connect. 
        Our platform consolidates invoicing, inventory management, payments, logistics, and more into one intuitive mobile application. 
        Say goodbye to the complexities of managing multiple software tools.`
    },
    {
        key: `Effortless Interaction:`,
        value: `At Nix Connect, we prioritize seamless interaction. 
        With touch-based features and a user-friendly interface, our platform minimizes the risk of manual errors. 
        Your business operations seamlessly integrate, ensuring a well-coordinated and error-free experience.`
    },
    {
        key: `Cost-Effective Solutions:`,
        value: `We understand the significance of cost savings for businesses. 
        Nix Connect simplifies your software expenses by providing an all-inclusive toolkit. 
        No more juggling subscriptions – just a straightforward, cost-effective solution.`
    },
    {
        key: `Simplicity in Operations:`,
        value: `Designed for simplicity, Nix Connect enables you to complete tasks with a few taps on your mobile device. 
        Spend less time on administrative work and more time focusing on the growth and expansion of your business.`
    },
    {
        key: `Advanced Features:`,
        value: `Nix Connect goes beyond the basics, offering advanced features such as low stock notifications, built-in email functionality, SMS capabilities, and more. 
        Our platform evolves with your business, ensuring it stays ahead of the curve.`
    },
    {
        key: `Intelligent Reporting System:`,
        value: `Experience the future with our intelligent reporting system, predicting the expiry dates of perishable products in advance. 
        Minimize waste, optimize stock, and enhance overall efficiency with Nix Connect.`
    },
    {
        key: `Our Commitment to Your Success:`,
        value: `At Nix Connect, we're not just a service; we're your reliable partner in the journey to business success. 
        Our commitment is to simplify, streamline, and elevate your business management experience.`
    }
]

export const WELCOME_MESSAGE = `
  Join us on this exciting venture to revolutionize the way you manage your business. 
  Welcome to Nix Connect – where innovation meets simplicity, and success is just a tap away.
`;
