import axios from 'axios';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { store } from '../../redux/store';
import { getConfig, validateAccessToken } from '../../utils/tokens';
import { CircularProgress, Modal } from '@mui/material';

export function didLogout() {
    return localStorage.length === 0;
}

function Logout() {

    const storeData = store.getState();
    const navigate = useNavigate();

    const [isFetching, setIsFetching] = useState(false);

    const logoutURL = process.env.REACT_APP_SERVICE_URI + "auth/logout/";

    const handleLogout = (event: any) => {
        setIsFetching(prevState => !prevState);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            setIsFetching(true);
            axios.post(logoutURL, {
                refresh: storeData.user.refresh_token
            }, getConfig(accessToken))
            .then((response) => {
                localStorage.clear();
                store.dispatch({type: 'USER_LOGOUT'});
                setIsFetching(false);
                navigate("/");
            })
            .catch((error) => {
                localStorage.clear();
                store.dispatch({type: 'USER_LOGOUT'});
                setIsFetching(false);
                alert('Unable to logout at the moment');
                navigate("/");
            });
            setIsFetching(false);
        })
    };

    return (
        <>
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
            <button type='submit' className='btn btn-danger' onClick={handleLogout}>Log out</button>
        </>
    )
}

export default Logout;