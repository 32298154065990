import React, {useState} from 'react';
import axios from "axios";
import ViewActiveUserDialog from './ViewActiveUserDialog';
import { IndividualActiveUser } from './IndividualActiveUser';
import { store } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';

export default (props: any) => {

    const [userData, setUserData] = useState<IndividualActiveUser>();
    const [showSelectedUser, setShowSelectedUser] = useState(false);    

    const storeData = store.getState();
	const navigate = useNavigate();

    const getActiveUser = async (viewActiveUserURL: string, config: any) => {
        try {
            const response = await axios.get(viewActiveUserURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const viewUserDetailsClicked = () => {
        if(didLogout()) {
            navigate("/");
        }
        const viewActiveUserId = props.data.id;
        const viewActiveUserURL = process.env.REACT_APP_SERVICE_URI + "users/" + viewActiveUserId;

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            getActiveUser(viewActiveUserURL, getConfig(accessToken))
            .then((data) => {
                setUserData(data);
            })
            .catch((error) => {
                alert('Loading failed!');
            });
            setShowSelectedUser((previous) => !previous);
        })
    };

    return (
        <div>
        <u style={{color:'#0D6EFD', cursor: 'pointer'}} onClick={() => viewUserDetailsClicked()}>Modify</u>
            {userData ? <ViewActiveUserDialog userId={props.data.id} showSelectedUser={showSelectedUser} setShowSelectedUser={setShowSelectedUser} userData={userData} setUserData={setUserData} />:null}
      </div>
    );
};