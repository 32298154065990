// TermsOfService.jsx
import React, { useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import {
  Container,
  ThemeProvider,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Theme,
  createTheme,
  Box,
  Grid,
  Paper,
  responsiveFontSizes,
} from '@mui/material';

import privacyPolicyData from './PrivacyPolicyData';
import NavBarGeneral from "../NavBarGeneral";
import Footer from '../Footer';

const PrivacyPolicy = () => {
  const loginURL = "/login";
  const enrolNow = "/enroll-your-business";
  const homeUrl = "/home";

  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Create a responsive typography variant for mobile devices
  const mobileTypographyVariant = isMobile ? 'h6' : 'h4';

  const [anchorEl, setAnchorEl] = React.useState(null);

  const gridContainerStyle: React.CSSProperties = {
    paddingLeft: isMobile ? '28px' : '54px',
    paddingRight: isMobile ? '28px' : '54px',
    paddingTop: isMobile ? '12px' : '36px',
    paddingBottom: isMobile ? '0px' : '0px',
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isMobile ? '112px' : '136px',
    marginBottom: '40px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
  };

  const gridContentStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    flex: 1,
    marginTop: isMobile ? '12px' : '36px',
  };

  // Create a responsive theme with adjusted typography for smaller screens
  let theme = createTheme();
  responsiveFontSizes(theme);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHomeClick = () => {
    handleMenuClose();
    navigate(homeUrl);
  };

  const handleSignInClick = () => {
    handleMenuClose();
    navigate(loginURL);
  };

  const handleEnrolNowClick = () => {
    handleMenuClose();
    navigate(enrolNow);
  };

  const handleNavBarItemClick = (key: String, value:String) => {
    if (key === 'menu_0') {
        handleHomeClick();
    } else if (key === 'menu_1') {
        handleEnrolNowClick();
    } else if (key === 'menu_2') {
        handleSignInClick();
    }
  };

  const navBarProps = {
    items: ['Home','Enrol Now', 'Sign In'],
    onItemClick: handleNavBarItemClick,
  };

  return (
    <ThemeProvider theme={theme}>
        <NavBarGeneral { ...navBarProps } />
        <Container>
          <Paper elevation={2} style={{ ...gridContainerStyle, display: 'flex', flexDirection: 'column', borderRadius: '8px', paddingTop: "24px"}}>
            <Grid container spacing={2} style={{ ...gridContentStyle }}>
              {privacyPolicyData.map((section, index) => (
                <Box key={index} marginBottom="20px">
                  <Typography variant={mobileTypographyVariant} component="h2" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    {section.section}
                  </Typography>
                  {Array.isArray(section.data) && (
                    <List>
                      {section.data.map((item, idx) => (
                        <ListItem key={idx} disableGutters>
                          <ListItemText 
                          primary={item.key} 
                          secondary={item.value}
                          style={{textAlign: "start"}} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
              ))}
            </Grid>
          </Paper>
        </Container>
     <Footer/>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
