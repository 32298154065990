import React, {useState} from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { didLogout } from '../../Common/Logout';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../redux/store';
import { getConfig, validateAccessToken } from '../../../utils/tokens';

export default (props: any) => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');

    const storeData = store.getState();
	const navigate = useNavigate();

    const handleClickOpen = (event: any) => {
		event.preventDefault();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setText(value);
	}

    const getActiveUser = async (viewActiveUserURL: string, config: any) => {
        try {
            const response = await axios.get(viewActiveUserURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const handleDelete = () => {
        if(didLogout()) {
            navigate("/");
        }
    const deleteSaleItemURL = process.env.REACT_APP_SERVICE_URI + "sales/" + props.data.order_id + "/";
		if (text.toLowerCase() === 'delete') {
            validateAccessToken().then((fetchedAccessToken) => {
                const accessToken = fetchedAccessToken || storeData.user.access_token as string;
                 axios.delete(deleteSaleItemURL, getConfig(accessToken))
                  .then((response) => {
                    alert('Deletion successful.');
                      window.location.reload();
                      handleClose();
                  })
                  .catch((error) => {
                      alert('Apologies, the removal of the Sale item is currently not possible.');
                      handleClose();
                      return;
                  });
            })
		}
    else {
      alert('Kindly enter the term "Delete" Only.');
      return;
    }
	}

    return (
        <div>
          <u style={{color:'#0D6EFD', cursor: 'pointer'}} onClick={handleClickOpen}>Delete</u>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Warning</DialogTitle>
                <DialogContent>
              <DialogContentText>
              Please type <b>"Delete"</b> if you want to delete Invoice Number <i><b>{props.data.invoice_number}</b></i>.
			      </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="delete"
              label="Type here"
              type="text"
              fullWidth
              variant="standard"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
            />
			    </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
};
