import React, { useState, useRef, useCallback, useMemo } from 'react';
import axios from "axios";
import {GridReadyEvent, FirstDataRenderedEvent} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ViewSelectedBusinessSalesButton from './ViewSelectedBusinessSalesButton';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import BusinessMemberNavbar from '../../BusinessMemberView/Navigation/BusinessMemberNavbar';
import '../../../pages/Common/styles.css';
import { didLogout } from '../../Common/Logout';
import { useNavigate } from 'react-router-dom';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { store } from '../../../redux/store';

function ViewSales(props: any) {

    const gridRef = useRef<AgGridReact<{business_name: string, business_owner_name: string, total_sales: string}>>(null);

    const [isFetching, setIsFetching] = useState(false);
    const [sales, setSales] = useState([]);
    
    const viewSalesURL = process.env.REACT_APP_SERVICE_URI + "sales/";

    const containerStyle = useMemo(() => ({ }), []);
    const gridStyle = useMemo(() => ({ }), []);

    const storeData = store.getState();

    const navigate = useNavigate();

    const [columnDefs, setColumnDefs] = useState([
        {headerName: 'Business Name', field: 'business_name', maxWidth: 600, flex: 2, resizable: true, filter: true},
        {headerName: 'Business Owner Name', field: 'business_owner_name', maxWidth: 600, flex: 2, resizable: true, filter: true},
        {headerName: 'Total Sales', field: 'total_sales', maxWidth: 600, flex: 1.5, resizable: true, filter: true, cellRenderer: (params: any) => {return params.data.currency_symbol + params.data.total_sales + " " + params.data.currency_code}},
        {field: '', flex: 1.5, cellRenderer: ViewSelectedBusinessSalesButton},
      ]);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;

            axios.get(viewSalesURL, getConfig(accessToken))
            .then((response) => {
                setSales(response.data);
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        });
    }, []);

    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : <BusinessMemberNavbar />}
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                }}
            >
                <CircularProgress />
            </Modal>
            <div className='ag-grid-size'>
			    <div style={containerStyle}>
				    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridRef} // Ref for accessing Grid's API
                            columnDefs={columnDefs}
                            rowData={sales}
                            animateRows={true}
                            domLayout='autoHeight'
                            onGridReady={onGridReady}
                        >
                        </AgGridReact>
					</div>
				</div>
			</div>
        </>
    )
}

export default ViewSales;