import { createStore, combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from './userSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { salesSlice } from './salesSlice';

const persistConfig = {
  key: 'root',
  storage,  //can be changed to session storage
}

const reducer = combineReducers({
    user: userSlice.reducer,
    sales: salesSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, reducer)

const checkRefreshTokenExpirationMiddleware = store => next => action => {
    const expiresIn = new Date(Date.parse(localStorage.getItem("expires-in")));
    if (expiresIn < Date.now()) {
        next(action);
        localStorage.clear();
        storage.removeItem('persist:root')
        return persistedReducer(undefined, action);
    }
    next(action);
};

export const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      storage.removeItem('persist:root')
      return persistedReducer(undefined, action);
    }
    return persistedReducer(state, action);
  }

export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk]
})
  
export const persistor = persistStore(store)