import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface UserStateProps {
  id: string,
  email: string,
  name: string,
  role: string,
  user_profile_id: string,
  tokens: {
    access: string,
    created_at: string,
    expires_in: string,
    refresh: string,
    token_type: string
  }
}

interface UserState {
    id: string,
    email: string,
    name: string,
    role: string,
    user_profile_id: string,
    access_token: string,
    refresh_token: string,
    expiration_time: string
}

const initialState = { name: '', role: '', email:'', user_profile_id: '', access_token: '', refresh_token: '', expiration_time: ''} as UserState

const getExpirationTime = (createdAt: string, expiresIn: string) => {
  let expirationTime = Number(createdAt) + Number(expiresIn);
  return new Date(expirationTime * 1000).toString();
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    save(state, action: PayloadAction<UserStateProps>) {
      state.name = action.payload.name
      state.role = action.payload.role
      state.email = action.payload.email
      state.user_profile_id = action.payload.id
      state.refresh_token = action.payload.tokens.refresh
      state.access_token = action.payload.tokens.access
      state.expiration_time = getExpirationTime(action.payload.tokens.created_at, action.payload.tokens.expires_in)
    },
    updateAccessToken(state, action: PayloadAction<string>) {
      state.access_token = action.payload
    },
    updateExpirationTime(state, action: PayloadAction<{created_at: string, expires_in: string}>) {
      state.expiration_time = getExpirationTime(action.payload.created_at, action.payload.expires_in)
    }
  },
})