import React, {useState, useEffect} from 'react';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { PendingUser } from './PendingUser';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import '../../../pages/Common/styles.css';
import { Box, CircularProgress, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';

interface PendingUserDialogProps {
    userId: string,
    showPendingUser: boolean,
    setShowPendingUser: React.Dispatch<React.SetStateAction<any>>,
    userData: PendingUser,
    setUserData: React.Dispatch<React.SetStateAction<any>>
}

interface DialogTitleProps{
    children: any,
    id: string,
    onClose: any
}

const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    border: 1,
    p: 1,
    m: -1.5,
    mt: 0
};

function PendingUserDialog(props: PendingUserDialogProps) {

    const [businesses, setBusinesses] = useState<{id: string, company_business_profile_id: String, company_business_name: string}[]>([]);
    const [selectedBusiness, setSelectedBusiness] = useState<string>('');
    const [selectedCompanyBusinessProfileId, setSelectedCompanyBusinessProfileId] = useState<string>('');
    const [selectedFeatures, setSelectedFeatures] = useState<{title: string, feature_id: string}[]>([]);
    const [availableFeatures, setAvailableFeatures] = useState<{title: string, feature_id: string}[]>([]);
    const [userBusinessProfileIds, setUserBusinessProfileIds] = useState<{business_id: string, profile_id: string, invited_user_id: string}[]>([]);

    const [isFetching, setIsFetching] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const storeData = store.getState();
	const navigate = useNavigate();

    const getBusinesses = async (getBusinessesURL: string, config: any) => {
        try {
            const response = await axios.get(getBusinessesURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const getBusiness = async (getBusinessURL: string, config: any) => {
        try {
            const response = await axios.get(getBusinessURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const getBusinessesURL = process.env.REACT_APP_SERVICE_URI + "businesses/";
            getBusinesses(getBusinessesURL, getConfig(accessToken))
            .then((data) => {
                setBusinesses(data);
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        })
    }, []);

    const handleRoleChange = (event: SelectChangeEvent) => {
        props.setUserData((prevState: any) => {
            return {...prevState, role: event.target.value}
        }) 
    };

    const handleBusinessSelect = (event: SelectChangeEvent) => {
        setSelectedFeatures([]);
        setSelectedBusiness(event.target.value); 
        const getBusinessURL = process.env.REACT_APP_SERVICE_URI + "businesses/" + event.target.value + "/";
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;

            getBusiness(getBusinessURL, getConfig(accessToken))
            .then((data) => {
                setSelectedCompanyBusinessProfileId(data.company_business_profile.id);
                setAvailableFeatures(data.nix_connect_app_profiles);
            })
            .catch((error) => {
                alert("Couldn't load business data!");
            });
        })
    };

    const handleOnChangeFeatures = (event: any) => {
        const title = event.target.value;
        const feature_id = event.target.id;
        const isChecked = event.target.checked;

        if(feature_id === 'selectAll') {
            if (isChecked) {
                setSelectedFeatures(prevState => []);
                availableFeatures.map(availableFeature => {
                    let feature = Object.assign({}, {feature_id: availableFeature.feature_id, title: availableFeature.title});
                    setSelectedFeatures(prevState => [...prevState, feature])
                })
            } else {
                setSelectedFeatures(prevState => []);
            }
        } else {
            if (isChecked) {
                setSelectedFeatures([...selectedFeatures, {'feature_id': feature_id, 'title': title}])
            } else {
                const filteredList = selectedFeatures.filter((item) => item.feature_id !== feature_id);
                setSelectedFeatures(filteredList);
            }
        }
    }

    // const getUserBusinessProfileId = async (getUserBusinessProfileURL: string) => {
    //     try {
    //         const response = await axios.post(getUserBusinessProfileURL, {
    //             nix_connect_app_profiles: selectedFeatures,
    //             email: props.userData.email,
    //             user_profile_id: props.userId,
    //             company_business_profile_id: props.userData.user_business_profiles.id
    //         }, config)
    //         return response.data.id;
    //     } catch (error) {
    //         return error;
    //     }
    // };

    const handleViewUserDetailsDecline = () => {
        // console.log('handleViewUserDetailsDecline')
        // const deletePendingUserId = props.userData.id;
        // const deletePendingUserURL = process.env.REACT_APP_SERVICE_URI + "pending/" + deletePendingUserId;

        // axios.delete(deletePendingUserURL, {
        //     headers: { 'Authorization': `Bearer ${storeData.user.access_token}` },
        //     data: { id: props.userData.id }
        // })
        // .then((response) => {
        //     alert('Declined user successfully')
        // })
        // .catch((error) => {
        //     alert('Sorry unable to decline user at the moment');
        // });
        // props.setShowPendingUser(false);
    };

    const handleCreateUserBusinessProfile = () => {
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const getUserBusinessProfileIdURL = process.env.REACT_APP_SERVICE_URI + "business_profile/";
            const invitedUserId = props.userData.invited_user_id
            axios.post(getUserBusinessProfileIdURL, {
                nix_connect_app_profiles: selectedFeatures,
                email: props.userData.email,
                user_profile_id: props.userId,
                is_user_attached: true,
                business_id: selectedBusiness,
                company_business_profile_id: selectedCompanyBusinessProfileId
            }, getConfig(accessToken))
            .then((response) => {
                setUserBusinessProfileIds(prevState => [...prevState, {business_id: selectedBusiness, profile_id : response.data.id, invited_user_id : invitedUserId}]);
                alert("Successfully created Business Profile for user")  
            })
            .catch((error) => {
                alert("We're Sorry. Unable to create business user profile right now!");
            })
        })
    }

    const handleViewUserDetailsAccept = () => {
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            const acceptPendingUserId = props.userId;
            const acceptPendingUserURL = process.env.REACT_APP_SERVICE_URI + "pending/" + acceptPendingUserId + "/";
            axios.patch(acceptPendingUserURL, {
                name: props.userData.name,
                email: props.userData.email,
                role: props.userData.role,
                user_business_profiles: userBusinessProfileIds
            }, getConfig(accessToken))
            .then((response) => {
                if(window.confirm('Accepted user successfully')) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                if(window.confirm('Unable to accept user at the moment')) {
                    window.location.reload();
                }
            });

            props.setShowPendingUser(false);
        })
    };

    function BootstrapDialogTitle(dialogTitleProps: DialogTitleProps) {
        const { children, onClose, ...other } = dialogTitleProps;
      
        return (
          <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
        );
    }

    const handleShowPendingUser = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        props.setShowPendingUser(value)
    }

        return (
        <Dialog fullScreen={fullScreen} open={props.showPendingUser} aria-labelledby="responsive-dialog-title" 
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1100px",
                    },
                },
            }}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={(event: any) => handleShowPendingUser(event, false)}>
                User Details
            </BootstrapDialogTitle>
            <DialogContent>
                <Modal
                    open={isFetching}
                    style={{
                        position: 'absolute',
                        height: 50,
                        width: 100,
                        margin: 'auto'
                        }}
                >
                    <CircularProgress />
                </Modal>
                <form>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="name" label="Name" aria-describedby="nameHelp" value={props.userData.name} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="email" className="form-control me-3 col" id="email" label="Email" aria-describedby="emailHelp" value={props.userData.email} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <FormControl className="form-control col">
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={props.userData.role}
                                label="Role"
                                onChange={handleRoleChange}
                            >
                                <MenuItem value={'BUSINESS_OWNER'}>Owner</MenuItem>
                                <MenuItem value={'BUSINESS_MEMBER'}>Member</MenuItem>
                                <MenuItem value={'GENERAL_USER'}>User</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <label>Contact Details</label>
                    <div className='form-group my-2 row'>
                        <TextField type="number" className="form-control me-3 col" id="mobileNumber" label="Mobile Number" aria-describedby="mobileNumberHelp" value={props.userData.contact_details.mobile} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="email" className="form-control me-3 col" id="alternateEmail" label="Alternate Email" aria-describedby="alternateEmailHelp" value={props.userData.contact_details.alt_email} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="number" className="form-control col" id="landlineNumber" label="Landline Number" aria-describedby="landlineNumberHelp" value={props.userData.contact_details.landline} variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>
                    
                    <label>Address</label>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" id="addressLine1" label="Address Line 1" aria-describedby="addressLine1Help" value={props.userData.address.address_line_1} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control me-3 col" id="addressLine2" label="Address Line 2" aria-describedby="addressLine2Help" value={props.userData.address.address_line_2} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="number" className="form-control col" id="postCode" label="Post Code" aria-describedby="postCodeHelp" value={props.userData.address.post_code} required variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>
                    <div className='form-group my-2 row'>
                        <TextField type="text" className="form-control me-3 col" id="city" label="City" aria-describedby="cityHelp" value={props.userData.address.city} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control me-3 col" id="state" label="State" aria-describedby="stateHelp" value={props.userData.address.state} required variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control col" id="country" label="Country" aria-describedby="countryHelp" value={props.userData.address.country} required variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>
         
                    {/*<label>Tax Info</label>
                    <div className='form-group my-2 row'>
                        <TextField type="text" className="form-control me-3 col" id="abn" label="Abn" aria-describedby="abnHelp" value={props.userData.tax_info.abn} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control me-3 col" id="taxId01" label="Tax Id 01" aria-describedby="taxId01Help" value={props.userData.tax_info.tax_id_01} variant="outlined" InputProps={{readOnly: true,}}/>
                        <TextField type="text" className="form-control col" id="taxId02" label="Tax Id 02" aria-describedby="taxId02Help" value={props.userData.tax_info.tax_id_02} variant="outlined" InputProps={{readOnly: true,}}/>
                    </div>*/}

                    <Box sx={{ ...commonStyles, borderRadius: 1 }}>

                        <div>
                        <label>Select Business
                            <br />
                            <br />
                            <FormControl className="form-control me-3 col">
                                <InputLabel>Business</InputLabel>
                                <Select
                                    value={selectedBusiness}
                                    label="Business"
                                    onChange={handleBusinessSelect}
                                >
                                    {businesses.map((item: any) => {
                                        return <MenuItem value={item.id}>{item.company_business_name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </label>
                        </div>

                        {availableFeatures.length!==0 ? 
                            <>
                                <label>Select Features</label>
                                <div>
                                    <input
                                        type="checkbox"
                                        name="features"
                                        id='selectAll'
                                        value={'Select All'}
                                        checked={availableFeatures.length === selectedFeatures.length}
                                        onChange={handleOnChangeFeatures}
                                    />
                                    <label>Select All</label>
                                    {availableFeatures.map((item: {title: string, feature_id: string}, index) => {
                                        return (
                                            <div key={item.feature_id} className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="features"
                                                    id={item.feature_id}
                                                    value={item.title}
                                                    checked={selectedFeatures.some(selectedFeature => selectedFeature.feature_id === item.feature_id)}
                                                    onChange={handleOnChangeFeatures}
                                                />
                                                <label>{item.title}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        : null}
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCreateUserBusinessProfile}>Create Business Profile</Button>
                <Button onClick={handleViewUserDetailsAccept}>Accept</Button>
                <Button onClick={handleViewUserDetailsDecline}>Decline</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PendingUserDialog;