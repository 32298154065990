import React from "react";
import {useNavigate} from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Stack,
  Menu,
  MenuItem,
  useMediaQuery,
  IconButton,
  Button
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { APP_NAME, APP_TAG_LINE } from './HomePageInformation';
import './styles.css';
import theme from "../../themes/theme";

const NavBarGeneral = ({ items, onItemClick }: any) => {

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const homeUrl = "/";

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHomeClick = () => {
    if (onItemClick) {
        onItemClick('menu_0' ,items[0]);
    }
  };

  const handleEnrolNowClick = () => {
    if (onItemClick) {
        onItemClick('menu_1' ,items[1]);
    }
  };

  const handleSignInClick = () => {
    if (onItemClick) {
        onItemClick('menu_2' , items[2]);
    }
  };

  const handleNaveBarHomeClick = () => {
    navigate(homeUrl);
  };


  return (
    <AppBar position="fixed" >
        <Toolbar>
            <img 
            src={process.env.PUBLIC_URL + '/resources/nixel_website_logo.png'} 
            alt="Company Logo" 
            style={{ maxWidth: isMobile ? 64 : 72 , marginRight: 18, cursor: 'pointer' }}
            onClick={handleNaveBarHomeClick}  />
            <Stack direction="row" spacing={isMobile ? 0 : 4} justifyContent="space-between" alignItems="center" width="100%" paddingTop="16px" paddingBottom="16px">
            <Stack direction="column" spacing={0}>
                <Typography variant= {isMobile ? "h5" : "h4"}  component="div" style={{ fontFamily: 'Poppins', fontWeight: 600 }}>
                  {APP_NAME}
                </Typography>
                <Typography variant="body1" component="div" fontStyle="italic" >
                  {APP_TAG_LINE}
                </Typography>
            </Stack>
            {isMobile ? (
                <>
                <IconButton color="inherit" onClick={handleMenuOpen}>
                    <MenuIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose} >
                    { items[0] &&  <MenuItem onClick={handleHomeClick}>{items[0]}</MenuItem> }
                    { items[1] &&  <MenuItem onClick={handleEnrolNowClick}>{items[1]}</MenuItem> }
                    { items[2] &&  <MenuItem onClick={handleSignInClick}>{items[2]}</MenuItem> }
                </Menu>
                </>
            ) : (
                <Stack direction="row" spacing={4} style={{ marginRight: '60px' }}>
                    { items[0] && <Button onClick={handleHomeClick} color="inherit" style={{fontSize: '18px', textTransform: 'none'}}>{items[0]}</Button> }
                    { items[1] && <Button onClick={handleEnrolNowClick} color="inherit" style={{fontSize: '18px', textTransform: 'none'}}>{items[1]}</Button> }
                    { items[2] && <Button onClick={handleSignInClick} color="inherit" style={{fontSize: '18px', textTransform: 'none'}}>{items[2]}</Button> }
                </Stack>
            )}
            </Stack>
        </Toolbar>
    </AppBar>
  );
};

export default NavBarGeneral;
