import { useState } from "react";
import { store } from "../redux/store";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedMemberRoute = () => {
    const userSlice = store.getState();

    const [member, setMember] = useState(userSlice.user.role === 'BUSINESS_MEMBER' ? true : null);

    return member ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedMemberRoute;