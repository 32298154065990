import axios from "axios";
import { store } from "../redux/store";
import { userSlice } from "../redux/userSlice";

export const getConfig = (key: string) => {
    return {
        headers: { Authorization: `Bearer ${key}` }
    };
}

export const validateAccessToken = async () => {

	const refreshTokenURL = process.env.REACT_APP_SERVICE_URI + "auth/token/refresh/";

    const storeData = store.getState();

    const config = {
        headers: { 'Authorization': `Bearer ${storeData.user.access_token}` }
    };

    let newAccessToken = '';

    const currentTime = new Date();
    const fetchTokenTime = new Date(storeData.user.expiration_time as string);
    fetchTokenTime.setMinutes(fetchTokenTime.getMinutes() - 5)
    if (new Date(fetchTokenTime) < currentTime) {
        await axios.post(refreshTokenURL, {
            refresh: storeData.user.refresh_token
        }, config)
        .then((response) => {
            if(response.data.detail.toLowerCase() === 'success') {
                store.dispatch(userSlice.actions.updateAccessToken(response.data.access));
                store.dispatch(userSlice.actions.updateExpirationTime(response.data));
                newAccessToken = response.data.access;
            } else {
                alert("Unable to perform requested operation at the moment");
                localStorage.clear();
                store.dispatch({type: 'USER_LOGOUT'});
                window.location.reload();
            }
        })
        .catch((error) => {
            alert("Session timed out. Please login again!");
            localStorage.clear();
            store.dispatch({type: 'USER_LOGOUT'});
            window.location.reload();
        });
    }
    return newAccessToken;
}