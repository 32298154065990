import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface SalesState {
  selectedBusiness: string,
  selectedBusinessMember: {businessProfileId: string, memberProfileId: string},
  selectedOrder: string,
}

const initialState = { selectedBusiness: '', selectedBusinessMember: {businessProfileId: '', memberProfileId: ''}, selectedOrder:'' } as SalesState

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    updateSelectedBusiness(state, action: PayloadAction<string>) {
      state.selectedBusiness = action.payload
    },
    updateSelectedBusinessMember(state, action: PayloadAction<{businessProfileId: string, memberProfileId: string}>) {
      state.selectedBusinessMember = action.payload
    },
    updateSelectedOrder(state, action: PayloadAction<string>) {
      state.selectedOrder = action.payload
    },
  },
})