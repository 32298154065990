import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../SuperAdminView/Navigation/SuperAdminNavbar.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import UserProfile from '../../Common/UserProfile';
import theme from "../../../themes/theme";
import {
	useMediaQuery,
  } from "@mui/material";


function BusinessMemberNavbar() {
  // const [showSales, setShowSales] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Navbar collapseOnSelect expand="lg" className="text-white bg-body-tertiary navbar" variant='dark'>
      <Container>
        {/* <Navbar.Brand href="/member/home">Nix Connect</Navbar.Brand> */}
        <Navbar.Brand href="/admin/home">
          <img 
            src={process.env.PUBLIC_URL + '/resources/nixel_website_logo.png'} 
            alt="Company Logo" 
            style={{ maxWidth: isMobile ? 56 : 64 , marginRight: 18, cursor: 'pointer' }}
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav-item text-light">
            <NavDropdown title="Sales" id="collasible-nav-dropdown"
            // show={showSales} onMouseEnter={() => setShowSales(prevState => !prevState)} onMouseLeave={() => setShowSales(prevState => !prevState)}
            >
              <NavDropdown.Item as={Link} to="/member/view-sales">View Sales</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <UserProfile />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BusinessMemberNavbar;