import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { store } from '../../../redux/store';


export default (props: any) => {
  	const [open, setOpen] = useState(false);
  	const [text, setText] = useState('');

	const storeData = store.getState();

	const deleteBusinessURL = process.env.REACT_APP_SERVICE_URI + "businesses/" + props.data.id + "/";

	const handleClickOpen = (event: any) => {
		event.preventDefault();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setText(value);
	}

	const handleDelete = () => {
		validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
			if (text.toLowerCase() === 'delete') {
				axios.delete(deleteBusinessURL, getConfig(accessToken))
				.then((response) => {
					alert('Deleted business successfully');
					handleClose();
					window.location.reload();
				})
				.catch((error) => {
					alert('Sorry unable to delete business at the moment');
					handleClose();
					window.location.reload();
				});
			}
		})
	}

	return (
		<span
			style={props.data.is_user_business_profile_attached ? {color:'#0D6EFD', cursor: 'not-allowed'}: {color:'#0D6EFD', cursor: 'pointer'}}
		>
		<u
			onClick={handleClickOpen}
			style={props.data.is_user_business_profile_attached ? {color:'#0D6EFD', pointerEvents: 'none'}: {color:'#0D6EFD', cursor: 'pointer'}}
		>
			Delete
		</u>

		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Warning</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Please type Delete if you want to delete business <i>{props.data.company_business_name}</i>
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id="delete"
				label="Type here"
				type="text"
				fullWidth
				variant="standard"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
			/>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose}>Cancel</Button>
			<Button onClick={handleDelete}>Delete</Button>
			</DialogActions>
		</Dialog>
		</span>
	);
};