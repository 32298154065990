import {
  CONTACT_PHONE, 
  CONTACT_EMAIL, 
  TERMS_OF_SERVICE,
  POLICY_LAST_UPDATED_DATE } from '../GlobalValues';

const disclaimerData = [
  {
    section: 'Disclaimer for Nix Connect App',
    data: [
      { key: 'Effective Date', value: `${POLICY_LAST_UPDATED_DATE}` },
      {
        key: 'Introduction',
        value: 'Welcome to Nix Connect! Nix Connect is the product of NiXel Systems Pty Ltd, a registered company in Australia. This disclaimer outlines important information regarding the use of our Nix Connect Application. Please carefully read and understand the terms below to make informed decisions about your data and privacy.',
      },
      {
        key: 'No Guarantee of Accuracy',
        value: 'While we strive to provide accurate and up-to-date information, Nix Connect makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the app or the information contained on it. Any reliance you place on such information is therefore strictly at your own risk.',
      },
      {
        key: 'Data Security',
        value: 'We prioritize the security of your data and employ industry-standard measures to protect it. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security. Nix Connect is not responsible for any unauthorized access, disclosure, alteration, or destruction of data that occurs beyond our reasonable control.',
      },
      {
        key: 'User Responsibilities',
        value: 'Users of Nix Connect are responsible for maintaining the confidentiality of their account information, including passwords, and for restricting access to their devices. Users agree to accept responsibility for all activities that occur under their account or password.',
      },
      {
        key: 'Third-Party Services',
        value: 'Nix Connect may contain links to third-party websites or services. These links are provided for your convenience, and Nix Connect does not endorse or assume responsibility for the content, privacy policies, or practices of any third-party sites or services. We encourage you to review the privacy policies of these third parties before engaging with their services.',
      },
      {
        key: 'Limitation of Liability',
        value: 'To the fullest extent permitted by applicable law, in no event shall Nix Connect or its affiliates be liable for any direct, indirect, incidental, consequential, special, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses arising out of or in connection with the use or inability to use the app.',
      },
      {
        key: 'Changes to Disclaimer',
        value: 'Nix Connect reserves the right to update or change this disclaimer at any time without prior notice. It is your responsibility to check this disclaimer periodically for changes. Your continued use of the app after any modifications indicates acceptance of the updated terms.',
      },
      {
        key: 'Contact Us',
        value: 'If you have any questions or concerns regarding this disclaimer, please contact us at contactus@nixelsystems.com.',
      },
      {
        key: 'Date of Last Update',
        value: `${POLICY_LAST_UPDATED_DATE}`,
      },
    ],
  },
];
  export default disclaimerData;
  