import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { 
    ThemeProvider,
    Modal, 
    Container,
    useMediaQuery,
    TextField,
 } from '@mui/material';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { APP_NAME, APP_TAG_LINE }  from './GlobalValues';
import './styles.css';
import { version } from '../../components/Common/Constants';
import theme from "../../themes/theme";
import NavBarGeneral from "./NavBarGeneral";
import Footer from './Footer';

function ForgotPasswordEmail() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const resetPasswordByEmailURL = process.env.REACT_APP_SERVICE_URI + "auth/forgot-password-email/";
    const enrolNow = "/enroll-your-business";
    const homeUrl = "/home";
    const loginURL = "/login";

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleLogin = (event: any) => {
        setIsFetching(true);
        event.preventDefault();

        if (email != null) {
            axios.post(resetPasswordByEmailURL, {
                email: email
            })
            .then((response) => {
                const data = response.data;
                const message = data.message;
                if (message.toLowerCase() === 'success') {
                    alert(data.detail);
                    navigate("/");
                } else {
                    alert('Unable to update reset your passowrd. Please try again later or contact NiXel Systems');
                }
                localStorage.clear();
                setIsFetching(false);
            })
            .catch((error) => {
                alert('Unable to update reset your passowrd. Please try again later or contact NiXel Systems');
                setIsFetching(false);
            });
        }
    }

    const gridContainerStyle: React.CSSProperties = {
        height: '100vh',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: '#FFFFFF00',
        borderRadius: '6px',
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
      };

    const handleHomeClick = () => {
        handleMenuClose();
        navigate(homeUrl);
      };

    const handleEnrolNowClick = () => {
        handleMenuClose();
        navigate(enrolNow);
    };

    const handleSignInClick = () => {
        handleMenuClose();
        navigate(loginURL);
    };

    const handleNavBarItemClick = (key: String, value:String) => {
        if (key === 'menu_0') {
            handleHomeClick();
        } else if (key === 'menu_1') {
            handleEnrolNowClick();
        } else if (key === 'menu_2') {
            handleSignInClick();
        }
    };

    const navBarProps = {
        items: ['Home','Enrol Now', 'Sign In'],
        onItemClick: handleNavBarItemClick,
    };

    return (
        <ThemeProvider theme={theme}>
            <NavBarGeneral { ...navBarProps } />
            <Container>
                <Box className={`grid-container ${isSmallScreen ? 'margin-small-screen' : 'margin-large-screen'}`}>
                    <Box className={isSmallScreen ? "center-without-bg-image " : "center"} >
                        <div className="login-form">
                            <h1 style={{display: 'flex', justifyContent: 'center',  marginTop: '12px'}}>Forgot Password</h1>
                            <form onSubmit={handleLogin} >
                                <div className="form-group my-3 row" >
                                    <Box style={{ marginTop: '12px' }}>
                                        <TextField 
                                            type="email" 
                                            className="form-control col" 
                                            id="inputEmail" 
                                            label="Email" 
                                            InputLabelProps={{ style: { color: 'grey' }}}
                                            aria-describedby="emailHelp" 
                                            onChange={handleEmailChange} 
                                            required 
                                            variant="outlined" />
                                    </Box>
                                </div>
                                <div className="form-group my-3 row">
                                    <Box display="flex" justifyContent="flex-end">
                                        <Link 
                                            className="hyper-link-right-align"
                                            href={loginURL}
                                            underline="hover">
                                            Return to Login
                                        </Link>
                                    </Box>
                                </div>
                                <Modal
                                    open={isFetching}
                                    style={{
                                        position: 'absolute',
                                        height: 120,
                                        width: 120,
                                        margin: 'auto'
                                    }}>
                                    <CircularProgress />
                                </Modal>
                                <center><button type="submit" className="btn btn-sign-in col" style={{ marginTop: '24px', borderRadius: '36px' }}>Receive Reset Link</button></center>
                                <center style={{ marginTop: '12px' }}>{APP_NAME} : {version}</center>
                            </form>
                        </div>
                    </Box>
                </Box>
            </Container>
            <Footer/>
        </ThemeProvider>
    )
}

export default ForgotPasswordEmail;