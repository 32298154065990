import React, {useEffect} from 'react';
import CreateFeature from '../../components/SuperAdminView/FeaturesManagement/CreateFeature';
import { useNavigate } from 'react-router-dom';
import { didLogout } from '../../components/Common/Logout';

function SuperAdminHomepage() {
    const navigate = useNavigate();
    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
    }, []);

    return (       
        <CreateFeature />
    )
}

export default SuperAdminHomepage;