import React, { useState, useEffect } from 'react';
import {useNavigate, useSearchParams, useLocation} from 'react-router-dom';
import axios from "axios";
import { User } from '../../components/Common/User';
import { Address } from '../../components/Common/Address';
import { ContactDetails } from '../../components/Common/ContactDetails';
// import { TaxInfo } from '../../components/Common/TaxInfo';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import './styles.css';
import { useQueryState } from '../../hooks/useQueryState';
import TextField from '@mui/material/TextField';

function Register() {
    const navigate = useNavigate();
    const registerURL = process.env.REACT_APP_SERVICE_URI + "auth/register/";

    const [id, setId] = useQueryState('profile_id');
    const [token, setToken] = useQueryState('token');
    const [email, setEmail] = useQueryState('email');
    const [user, setUser] = useState<User>();
    const [address, setAddress] = useState<Address>();
    const [contactDetails, setContactDetails] = useState<ContactDetails>();
//     const [taxInfo, setTaxInfo] = useState<TaxInfo>();

    const [queryParameters] = useSearchParams();

    const [password, setPassword] = useState({
        firstPassword: "",
        secondPassword: "",
    });
    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
        match,
        specialChar,
    ] = usePasswordValidation({
        firstPassword: password.firstPassword,
        secondPassword: password.secondPassword,
    });

    const setUserEmail = async () => {
        try {
                setUser((previousState: any) => {
                return {...previousState, email: email}
            });
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        setUserEmail();
    }, [email]);

    const passwordValidate = () => {
        if ( !hasNumber || !upperCase || !lowerCase || !specialChar || !validLength ) {
            alert('Your Password must satisfy following criteria\n1. At least one number\n2. At least one upper case letter\n3. At least one lower case letter\n4. At least one special character\n5. Length between 8-14 characters');
            return false;
        } else if( !match ) {
            alert('Both the passwords should match');
            return false;
        } else{
            return true;
        }
    }

    const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "name") {
            setUser((previousState: any) => {
                return {...previousState, name:event.target.value}
            });
        } else if (item === "password") {
            setUser((previousState: any) => {
                return {...previousState, password:event.target.value}
            });
        }
    }

    const handleContactDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "alt_email") {
            setContactDetails((previousState: any) => {
                return {...previousState, alt_email:event.target.value}
            });
        } else if (item === "mobile") {
            setContactDetails((previousState: any) => {
                return {...previousState, mobile:event.target.value}
            });
        } else if (item === "landline") {
            setContactDetails((previousState: any) => {
                return {...previousState, landline:event.target.value}
            });
        }
    }

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "address_line_1") {
            setAddress((previousState: any) => {
                return {...previousState, address_line_1:event.target.value}
            });
        } else if (item === "address_line_2") {
            setAddress((previousState: any) => {
                return {...previousState, address_line_2:event.target.value}
            });
        } else if (item === "city") {
            setAddress((previousState: any) => {
                return {...previousState, city:event.target.value}
            });
        } else if (item === "state") {
            setAddress((previousState: any) => {
                return {...previousState, state:event.target.value}
            });
        } else if (item === "country") {
            setAddress((previousState: any) => {
                return {...previousState, country:event.target.value}
            });
        } else if (item === "post_code") {
            setAddress((previousState: any) => {
                return {...previousState, post_code:event.target.value}
            });
        }
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, firstPassword: event.target.value });
    };
    const handleReEnterPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, secondPassword: event.target.value });
    };

    const handleRegister = (event: any) => {
        event.preventDefault();
        setUserEmail();
        if (passwordValidate()) {
            axios.post(registerURL, {
                id: id,
                token: token,
                user: user,
                address: address,
                contact_details: contactDetails,
//                 tax_info: taxInfo
            })
            .then((response) => {
                const message = response.data.message;
                if (message.toLowerCase() !== 'success') {
                    alert('Unable to register. Please try after some time.');
                } else if (message === 'success') {
                    alert('Congratulations. We have forwarded your details\n You will soon receive mail on further updates');
                    navigate("/");
                }
            })
            .catch((error) => {
            });
        }
    }

    return (
        <div className='center-with-image'>
            <div>
                <h3 style={{display: 'flex', justifyContent: 'center'}}>Sign Up</h3>
                <form onSubmit={handleRegister}>
                    <div className="form-group my-2 row">
                        <TextField type="email" className="form-control me-3 col" id="inputUser" aria-describedby="emailHelp" value={queryParameters.get('email') as string} />
                        <TextField type="text" className="form-control col" id="inputUser" aria-describedby="nameHelp" label="Enter Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUserChange(event, "name")} required variant="outlined" />
                    </div>
                    <div className="form-group my-2 row">
                        <TextField type="password" className="form-control me-3 col" id="inputUser" label="Password" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {handleUserChange(event, "password"); handlePasswordChange(event);}} required variant="outlined" />
                        <TextField type="password" className="form-control col" id="inputUser" label=" Re-enter Password" onChange={handleReEnterPasswordChange} onPaste={(e)=>{ e.preventDefault(); return false; }} required variant="outlined" />
                    </div>

                    <label>Contact Details</label>
                    <div className='form-group my-2 row'>
                        <TextField type="number" className="form-control me-3 col" label="Mobile Number" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleContactDetailsChange(event, "mobile")} required variant="outlined" />
                        <TextField type="email" className="form-control me-3 col" label="Alternate Email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleContactDetailsChange(event, "alt_email")} variant="outlined" />
                        <TextField type="number" className="form-control col" label="Landline Number" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleContactDetailsChange(event, "landline")} variant="outlined" />
                    </div>
                    

                    <label>Address</label>
                    <div className="form-group my-2 row">
                        <TextField type="text" className="form-control me-3 col" label="Address Line 1" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(event, "address_line_1")} required variant="outlined" />
                        <TextField type="text" className="form-control me-3 col" label="Address Line 2" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(event, "address_line_2")} variant="outlined" />
                        <TextField type="number" className="form-control col" label="Post Code" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(event, "post_code")} required variant="outlined" />
                    </div>
                    <div className='form-group my-2 row'>
                        <TextField type="text" className="form-control me-2 col" label="City" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(event, "city")} required variant="outlined" />
                        <TextField type="text" className="form-control me-3 col" label="State" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(event, "state")} required variant="outlined" />
                        <TextField type="text" className="form-control col" label="Country" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(event, "country")} required variant="outlined" />
                    </div>
                                        
                    {/*<label>Tax Info</label>
                    <div className='form-group my-2 row'>
                        <TextField type="text" className="form-control me-3 col" label="Abn" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxInfoChange(event, "abn")} variant="outlined" />
                        <TextField type="text" className="form-control me-3 col" label="Tax Id 01" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxInfoChange(event, "tax_id_01")} variant="outlined" />
                        <TextField type="text" className="form-control col" label="Tax Id 02" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxInfoChange(event, "tax_id_02")} variant="outlined" />
                    </div>*/}
                    
                    <center>
                        <div className="button-container">
                            <button type="submit" className="btn btn-primary" style={{width:"40%"}} >Register Me</button>
                            <button type="reset" className="btn-clear" style={{width:"40%"}}>Clear</button>
                        </div>
                    </center>
                </form>
            </div>
        </div>
      )
}

export default Register;