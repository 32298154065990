const appPermissionsData = [
  {
    title: 'Required Permissions for Nix Connect App:',
  },
  {
    category: '1. Bluetooth & Location',
    dataType: 'Approximate location',
    isRequired: true,
    description: 'In order to provide functionality for accessing Bluetooth-enabled printers, Nix Connect requires Bluetooth permissions. This includes both fine and coarse location permissions. These permissions are necessary to establish a connection with Bluetooth devices and ensure a seamless printing experience.',
  },
  {
    category: '2. Personal Info',
    dataType: 'Name',
    isRequired: true,
    description: 'We collect both Business name and User name. Represents how a user refers to themselves, such as their first or last name, or nickname.',
  },
  {
    dataType: 'Email address',
    isRequired: true,
    description: 'Both Business Email and User Email. Represents a user’s email address.',
  },
  {
    dataType: 'User IDs',
    isRequired: 'Upon successful registration',
    description: 'After completing the registration process successfully, you will need to use your user ID to log in. Your user ID is the email address you used during the registration.',
  },
  {
    dataType: 'Address',
    isRequired: true,
    description: 'Both Business and User address are required to create business and user. Represents a user’s address, such as a mailing or home address.',
  },
  {
    dataType: 'Phone number',
    isRequired: true,
    description: 'Both Business and User Phone number are required to create a valid Business. Represents a user’s phone number.',
  },
  {
    dataType: 'Other info',
    isRequired: 'Like Business Registration details',
    description: 'Represents any other personal information such as date of birth, gender identity, veteran status, etc.',
  },
  {
    category: '3. Financial Info',
    dataType: 'User payment method info',
    isRequired: true,
    description: 'Bank Account, Bank Address, Branch, PayID, UPI ID of Business and User. Information about a user’s financial accounts such as a credit card number.',
  },
  {
    category: '4. Photos and Videos',
    dataType: 'Photos',
    isRequired: true,
    description: 'We need access to the Gallery or Camera to take photos, which is required to create the online Product. Represents a user’s photos.',
  },
  {
    category: '5. Files and Docs',
    dataType: 'Files and Docs',
    isRequired: true,
    description: 'Nix Connect creates PDF, XLS, and Text files which are required for the app\'s defined features and functionality. Represents a user’s files or documents, or information about their files or documents such as file names.',
  },
  {
    category: '6. App Activity',
    dataType: 'App Interactions',
    isRequired: 'Add more details here',
    description: 'Information about how a user interacts with the app. For example, the number of times they visit a page or sections they tap on.',
  },
  {
    category: '7. App Info and Performance',
    dataType: 'Crash logs',
    isRequired: true,
    description: 'To fix the issues and make the app feature better. Represents crash log data from your app. For example, the number of times your app has crashed, stack traces, or other information directly related to a crash.',
  },
  {
    dataType: 'Diagnostics',
    isRequired: true,
    description: 'To make the app better. Represents information about the performance of your app. For example, battery life, loading time, latency, framerate, or any technical diagnostics.',
  },
  {
    dataType: 'Other app performance data',
    isRequired: true,
    description: 'Analytics Data. Represents any other app performance data not listed here.',
  },
];

export default appPermissionsData;
