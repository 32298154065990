import React from 'react';
import { Link } from 'react-router-dom';
import { store } from '../../../redux/store';

export default (props: any) => {

  	const userSlice = store.getState();

  	return (
    	<span>
      		<Link to={"/" + userSlice.user.role.split("_")[1].toLowerCase() + "/product-bulk-upload/"}
         		state={{business_id: props.data.business_id}} > Select </Link>
    	</span>
  	);
};

// This is under Product Bulk Upload