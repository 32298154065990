import React, {useState} from 'react';
import axios from "axios";
import { PendingUser } from './PendingUser';
import PendingUserDialog from './PendingUserDialog';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';


export default (props: any) => {

    const [userData, setUserData] = useState<PendingUser>();
    const [showPendingUser, setShowPendingUser] = useState(false); 
    
    const storeData = store.getState();
	const navigate = useNavigate();

    const getPendingUser = async (viewPendingUserURL: string, config: any) => {
        try {
            const response = await axios.get(viewPendingUserURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const viewUserDetailsClicked = () => {
        if(didLogout()) {
            navigate("/");
        }
        const viewPendingUserId = props.data.id;
        const viewPendingUserURL = process.env.REACT_APP_SERVICE_URI + "pending/" + viewPendingUserId;

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            getPendingUser(viewPendingUserURL, getConfig(accessToken))
            .then((data) => {
                setUserData(data);
            })
            .catch((error) => {
                alert('Loading failed!');
            });
            setShowPendingUser((previous) => !previous);
        })
    };

    // const handleOnChangeBusinesses = (event: any) => {
    //     const title = event.target.value;
    //     const feature_id= event.target.id;
    //     const isChecked = event.target.checked;
 
    //     if (isChecked) {
    //         setNiXConnectAppProfiles([...niXConnectAppProfiles, {'feature_id': feature_id, 'title': title}])
    //     } else {
    //         const filteredList = niXConnectProfiles.filter((item) => item.feature_id !== feature_id);
    //         setNiXConnectAppProfiles(filteredList);
    //     }
    // }

    return (
        <div>
        <u style={{color:'#0D6EFD', cursor: 'pointer'}} onClick={() => viewUserDetailsClicked()}>View User Details</u>
            {userData ? <PendingUserDialog userId={props.data.id} showPendingUser={showPendingUser} setShowPendingUser={setShowPendingUser} userData={userData} setUserData={setUserData} />:null}
      </div>
    );
};