import React from 'react';
import { Link } from 'react-router-dom';
import { store } from '../../../redux/store';

export default (props: any) => {

  	const userSlice = store.getState();

  	return (
    	<span>
      		<Link to={"/" + userSlice.user.role.split("_")[1].toLowerCase() + "/modify-business/"}
         		state={{business_id: props.data.id}} 
      		>
        		Modify
      		</Link>
    	</span>
  	);
};