import React, {useEffect} from 'react';
import InviteUser from '../../components/SuperAdminView/UserManagement/InviteUser';
import { useNavigate } from 'react-router-dom';
import { didLogout } from '../../components/Common/Logout';

function BusinessOwnerHomepage() {

    const navigate = useNavigate();
    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
    }, []);

    return (
        <InviteUser role={'BUSINESS_OWNER'} />
    )
}

export default BusinessOwnerHomepage;