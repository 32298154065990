import React, { useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import {
  Container,
  ThemeProvider,
  Grid,
  Typography,
  Paper,
  Stack,
  Card,
  useMediaQuery,
  Button
} from "@mui/material";
import { styled } from '@mui/system';
import { ABOUT_NIX_CONNECT, ABOUT_NIX_CONNECT_DETAILS, WHY_NIX_CONNECT_TITLE, NIXEL_AND_NIX_CONNECT, homePageInformationData, WELCOME_MESSAGE } from './HomePageInformation';
import './styles.css';
import theme from "../../themes/theme";
import NavBarGeneral from "./NavBarGeneral";
import Footer from './Footer';

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 

  const nixConnectImage = process.env.PUBLIC_URL + '/resources/nix_connect_product.jpg';
  const loginURL = "/login";
  const enrolNow = "/enroll-your-business";
  const homeUrl = "/home";

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const gridContainerStyle: React.CSSProperties = {
    padding: '16px',
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isSmallScreen ? '112px' : '136px',
    marginBottom: '40px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
  };

  const cardStyle: React.CSSProperties = {
    paddingTop: '16px', 
    paddingBottom: '16px',
    paddingRight: isSmallScreen ? '0px' : '8px',
    paddingLeft: isSmallScreen ? '0px' : '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const CardWithImage = styled('div')({
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
  });

  const MediaImage = styled('img')({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  });

const mainInfoTextStyle: React.CSSProperties = {
    textAlign: 'justify',
    marginTop: '8px',
    color: '#303030', // Text color set to black for better contrast
    fontFamily: 'Merriweather, sans',
    fontWeight: 'normal', // Use 'normal' for regular weight
    fontStyle: 'normal',
    letterSpacing: '.25px',
    lineHeight: 1.5,
    fontSize: '20px',
  };
  
  const welcomeMessageTextStyle: React.CSSProperties = {
    textAlign: 'center',
    marginTop: '8px',
    color: '#ffffff', // Text color set to black for better contrast
    fontFamily: 'Merriweather, serif',
    fontWeight: 'normal', // Use 'normal' for regular weight
    fontStyle: 'normal',
    letterSpacing: '1.2px',
    lineHeight: 1.5,
  };
  
const welcomeMessageCardStyle: React.CSSProperties = {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '12px',
    paddingLeft: '12px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'justify',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '8px',
    backgroundImage: 'linear-gradient(to bottom right, #002D70, #00569e)', // Use your preferred colors
    color: '#fff', // Set text color to white for better contrast
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.4)',
  };
  
  const titleTextStyle: React.CSSProperties = {
    textAlign: 'center',
    marginTop: '0px', 
    color: '#004AAD', 
    fontSize: '36px',
    fontFamily: 'Poppins',
    fontWeight: 'regular',
    fontStyle: 'normal',
    letterSpacing: '1.5px', 
    lineHeight: 1.2,
  };
 
const subTitleTextStyle: React.CSSProperties = {
    textAlign: 'left',
    marginTop: '0px',
    color: '#333', 
    fontSize: '24px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500, 
    fontStyle: 'normal',
    letterSpacing: '0.5px',
    lineHeight: 1.2, 
  };

const bodyContainerStyle: React.CSSProperties = {
    backgroundColor: 'linear-gradient(to bottom right, #FAF8F0, #FAF8F0)',
    padding: '12px', 
  };

const bodyTextStyle: React.CSSProperties = {
    textAlign: 'justify',
    justifyContent: 'start',
    marginTop: '16px',
    color: '#333', 
    fontSize: '20px',
    fontFamily: 'Merriweather, serif',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '.25px',
    lineHeight: 1.5,
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHomeClick = () => {
    handleMenuClose();
    navigate(homeUrl);
  };

  const handleSignInClick = () => {
    handleMenuClose();
    navigate(loginURL);
  };

  const handleEnrolNowClick = () => {
    handleMenuClose();
    navigate(enrolNow);
  };

  const handleNavBarItemClick = (key: String, value:String) => {
    if (key === 'menu_0') {
        handleHomeClick();
    } else if (key === 'menu_1') {
        handleEnrolNowClick();
    } else if (key === 'menu_2') {
        handleSignInClick();
    }
  };

  const navBarProps = {
    items: ['','Enrol Now', 'Sign In'],
    onItemClick: handleNavBarItemClick,
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBarGeneral { ...navBarProps } />
      <Container>
        <Paper elevation={2} style={{ ...gridContainerStyle, display: 'flex', flexDirection: 'column', borderRadius: '8px' }}>
          <Grid container spacing={2} style={{ flex: 1 }}>
            <Grid item xs={12}  style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper elevation={4} style={{ ...bodyContainerStyle, flex: 1, borderRadius: '8px', backgroundColor: '#004AAD' }}>
                  <Stack direction="column" spacing={0} style={{ ...cardStyle }}>
                    <Typography gutterBottom variant="h4" style={{ ...titleTextStyle, color: '#FFFFFF', fontSize: '32px' }}>
                        {ABOUT_NIX_CONNECT}
                    </Typography>
                  </Stack>
                </Paper>
            </Grid>       
            <Grid item xs={12} >
              <Stack direction="column" spacing={0} style={{padding: '0px'}}>
                <Typography gutterBottom variant="h6" style={{ ...mainInfoTextStyle }}>
                    {ABOUT_NIX_CONNECT_DETAILS}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <Card elevation={4} style={{ borderRadius: '8px' }} >
                <CardWithImage>
                    <MediaImage alt="Nix Connect" src={nixConnectImage} />
                </CardWithImage>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <Stack direction="column" spacing={0} style={{ ...cardStyle, padding: '0px' }}>
                  <Typography gutterBottom variant="body1" style={{ ...bodyTextStyle }}>
                    {NIXEL_AND_NIX_CONNECT}
                  </Typography>
              </Stack>
            </Grid>
          

          {/* Full-width Grid item for dynamic content */}
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', borderRadius: '0 0 8px 8px', marginTop: '24px' }}>
              <Grid item xs={12}  style={{ display: 'flex', flexDirection: 'column' }}>
                  <Paper elevation={4} style={{ ...bodyContainerStyle, flex: 1, borderRadius: '8px', backgroundColor: '#004AAD' }}>
                    <Stack direction="column" spacing={0} style={{ ...cardStyle, padding:'0px' }}>
                      <Typography gutterBottom variant="h4" style={{ ...titleTextStyle, color: '#FFFFFF', fontSize: '32px' }}>
                          {WHY_NIX_CONNECT_TITLE}
                      </Typography>
                    </Stack>
                  </Paper>
              </Grid>
              
              <Grid container spacing={2} style={{ flex: 1, borderRadius: '0 0 8px 8px', marginTop: '0px' }}>
                {homePageInformationData.map((item, index) => (
                  <Grid item xs={12} sm={6} key={item.key} style={{ display: 'flex', flexDirection: 'column', borderRadius: '8px' }}>
                        <Stack direction="column" spacing={0} style={{ ...cardStyle, flex: 1 }}>
                            <Typography gutterBottom variant="subtitle2" style={{ ...subTitleTextStyle, display: 'inline' }}>
                                {item.key}
                            </Typography>
                            <Typography gutterBottom variant="body1" style={{ ...bodyTextStyle, marginTop: '10px', padding:'0px'  }}>
                                {item.value}
                            </Typography>
                        </Stack>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>  
          <Paper elevation={4} style={{ 
            borderRadius: '8px', 
            marginTop: '16px' }}>
            <Grid item xs={12} >
                <Stack direction="column" spacing={0} style={{ ...welcomeMessageCardStyle }}>
                    <Typography gutterBottom variant="h6" style={{ ...welcomeMessageTextStyle, padding:'0px'  }}>
                        {WELCOME_MESSAGE}
                    </Typography>
                </Stack>
            </Grid>
          </Paper>
          <Button  
            onClick={handleEnrolNowClick} 
            style={{fontSize: '24px', width: isSmallScreen ? '100%' : '360px', textTransform: 'none', marginTop: '36px', borderRadius: '36px'}}
            variant="contained" 
            color="success" 
            size="large">
                Enrol Now
          </Button>
        </Paper>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
};

export default Home;
