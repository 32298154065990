import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import './invite-user.css'
import '../../../pages/Common/styles.css'
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { store } from '../../../redux/store';

function InviteUser(props: any) {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const storeData = store.getState();

    const inviteUserURL = process.env.REACT_APP_SERVICE_URI + "auth/invite/";

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
    }, [])

    const handleInvite = (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            if (name != null && email != null) {
                axios.post(inviteUserURL, {
                    name: name,
                    email: email
                }, getConfig(accessToken))
                .then((response: any) => {
                    const message = response.data.message;
                    setIsLoading(false);
                    alert(message);
                    window.location.reload();
                })
                .catch((error: any) => {
                    setIsLoading(false);
                    alert('Unable to invite user right now. Please try after sometime');
                });
            }
        })
    }

    const handleClear = (event: React.MouseEvent) => {
        setName('');
        setEmail('');
    }

    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : null}
                <div className="invite-user-form">
                    <div>
                        <h1>Invite User</h1>
                        <form onSubmit={handleInvite}>
                            <div className="form-group my-3 row">
                                <Box>
                                    <TextField 
                                        type="text" 
                                        className="form-control col" 
                                        id="inputName" 
                                        label="Name" 
                                        aria-describedby="nameHelp" 
                                        required 
                                        onChange={handleNameChange} 
                                        variant="outlined" />
                                </Box>  
                            </div>
                            <div className="form-group my-3 row">
                                <Box >
                                    <TextField 
                                        type="email" 
                                        className="form-control col" 
                                        id="inputEmail" 
                                        label="Email" 
                                        aria-describedby="emailHelp"
                                        required 
                                        onChange={handleEmailChange} 
                                        variant="outlined" />
                                </Box>
                            </div>
                            <Modal
                                open={isLoading}
                                style={{
                                    position: 'absolute',
                                    height: 120,
                                    width: 120,
                                    margin: 'auto'
                                }}
                            >
                                <CircularProgress />
                            </Modal>
                            <center>
                                <div className="button-container">
                                    <button type="submit" className="btn btn-primary" style={{width:"50%", marginTop: '12px', marginRight: "20px"}} >Invite </button>
                                    <button type="reset" className="btn-clear" onClick={handleClear} style={{width:"50%", marginTop: '12px'}}>Clear</button>
                                </div>
                            </center>
                        </form>
                    </div>
                </div>
        </>
    )
}

export default InviteUser;