import React, { useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import {
  Container,
  Typography,
  ThemeProvider,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Theme,
  Box,
  Paper,
  Grid,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
import dataPolicy from './DataPolicyData'; // Assuming dataPolicy.tsx is in the same directory
import NavBarGeneral from "../NavBarGeneral";
import Footer from '../Footer';

const DataPolicy: React.FC = () => {
  const loginURL = "/login";
  const enrolNow = "/enroll-your-business";
  const homeUrl = "/home";

  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Create a responsive typography variant for mobile devices
  const mobileTypographyVariant = isMobile ? 'h6' : 'h4';

  const [anchorEl, setAnchorEl] = React.useState(null);

  const gridContainerStyle: React.CSSProperties = {
    paddingLeft: isMobile ? '0px' : '24px',
    paddingRight: isMobile ? '0px' : '24px',
    paddingTop: isMobile ? '12px' : '36px',
    paddingBottom: isMobile ? '0px' : '0px',
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isMobile ? '112px' : '136px',
    marginBottom: '40px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
  };

  const gridContentStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    flex: 1,
    marginTop: isMobile ? '12px' : '36px',
  };

  // Create a responsive theme with adjusted typography for smaller screens
  let theme = createTheme();
  responsiveFontSizes(theme);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHomeClick = () => {
    handleMenuClose();
    navigate(homeUrl);
  };

  const handleSignInClick = () => {
    handleMenuClose();
    navigate(loginURL);
  };

  const handleEnrolNowClick = () => {
    handleMenuClose();
    navigate(enrolNow);
  };

  const handleNavBarItemClick = (key: String, value:String) => {
    if (key === 'menu_0') {
        handleHomeClick();
    } else if (key === 'menu_1') {
        handleEnrolNowClick();
    } else if (key === 'menu_2') {
        handleSignInClick();
    }
  };

  const navBarProps = {
    items: ['Home','Enrol Now', 'Sign In'],
    onItemClick: handleNavBarItemClick,
  };

  return (
    <ThemeProvider theme={theme}>
       <NavBarGeneral { ...navBarProps } />
      <Container>
        <Paper elevation={2} style={{ ...gridContainerStyle, display: 'flex', flexDirection: 'column', borderRadius: '8px', paddingTop: "4px"}}>
          <Grid container spacing={2} style={{ ...gridContentStyle }}>
            {dataPolicy.map((section, index) => (
              <Grid item xs={12} key={index}>
                <Box>
                <Typography variant={mobileTypographyVariant} component="h2" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    {section.section}
                  </Typography>
                  <List>
                    {section.data.map((item, itemIndex) => (
                      <ListItem key={itemIndex}>
                        <ListItemText
                          primary={
                            <Typography variant={isMobile ? 'body1' : 'body2'} style={{ fontWeight: 'bold' }}>
                              {item.key}
                            </Typography>
                          }
                          secondary={
                            Array.isArray(item.value) ? (
                              item.value.map((lineItem, lineIndex) => (
                                <div key={lineIndex}>
                                  {typeof lineItem === 'string' ? (
                                    <Typography variant={isMobile ? 'body2' : 'body1'}>{lineItem}</Typography>
                                  ) : (
                                    <Typography variant={isMobile ? 'body2' : 'body1'}>
                                      {Array.isArray(lineItem.value) ? (
                                        lineItem.value.map((line, lineNewIndex) => (
                                          <span key={lineNewIndex}>
                                            {line}
                                            {lineNewIndex < lineItem.value.length - 1 && <br />}
                                          </span>
                                        ))
                                      ) : (
                                        lineItem.value
                                      )}
                                    </Typography>
                                  )}
                                </div>
                              ))
                            ) : (
                              <Typography variant={isMobile ? 'body2' : 'body1'}>{item.value}</Typography>
                            )
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
};

export default DataPolicy;
