import React, { useState, useEffect } from 'react';
import {useNavigate, useSearchParams, useLocation} from 'react-router-dom';
import axios from "axios";
import { 
    ThemeProvider,
    Modal, 
    Container,
    useMediaQuery,
    Box,
    Link,
    TextField,
 } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import './styles.css';
import { useQueryState } from '../../hooks/useQueryState';
import { version } from '../../components/Common/Constants';
import { APP_NAME, APP_TAG_LINE }  from './GlobalValues';
import theme from "../../themes/theme";
import NavBarGeneral from "./NavBarGeneral";
import Footer from './Footer';

function ResetPasswordEmail() {
    const navigate = useNavigate();
    const passwordResetCompleteURL = process.env.REACT_APP_SERVICE_URI + "auth/password-reset-complete/";
    
    const enrolNow = "/enroll-your-business";
    const homeUrl = "/home";
    const loginURL = "/login";

    const [uidb64, setUidb64] = useQueryState('uidb64');
    const [token, setToken] = useQueryState('token');
    const [isFetching, setIsFetching] = useState(false);
    const [queryParameters] = useSearchParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [password, setPassword] = useState({
        firstPassword: "",
        secondPassword: "",
    });
    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
        match,
        specialChar,
    ] = usePasswordValidation({
        firstPassword: password.firstPassword,
        secondPassword: password.secondPassword,
    });

    const passwordValidate = () => {
        if ( !hasNumber || !upperCase || !lowerCase || !specialChar || !validLength ) {
            alert('Your Password must satisfy following criteria\n1. At least one number\n2. At least one upper case letter\n3. At least one lower case letter\n4. At least one special character\n5. Length between 8-14 characters');
            return false;
        } else if( !match ) {
            alert('Both the passwords should match');
            return false;
        } else{
            return true;
        }
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, firstPassword: event.target.value });
    };
    const handleReEnterPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, secondPassword: event.target.value });
    };

    const handleResetPassword = (event: any) => {
        event.preventDefault();
        if (passwordValidate()) {
            axios.patch(passwordResetCompleteURL, {
                password: password.firstPassword,
                token: token,
                uidb64: uidb64
            })
            .then((response) => {
                const message = response.data.message;
                if (message === 'success') {
                    alert('Congratulations. We have successfully updated your password');
                    navigate("/");
                } else {
                    alert('Unable to update your passowrd. Please try again');
                }
            })
            .catch((error) => {
                alert('Unable to update your passowrd. Please try again');
            });
        }
    }

    const gridContainerStyle: React.CSSProperties = {
        height: '100vh',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
      };

    const handleHomeClick = () => {
        handleMenuClose();
        navigate(homeUrl);
      };

    const handleEnrolNowClick = () => {
        handleMenuClose();
        navigate(enrolNow);
    };

    const handleSignInClick = () => {
        handleMenuClose();
        navigate(loginURL);
    };

    const handleNavBarItemClick = (key: String, value:String) => {
        if (key === 'menu_0') {
            handleHomeClick();
        } else if (key === 'menu_1') {
            handleEnrolNowClick();
        } else if (key === 'menu_2') {
            handleSignInClick();
        }
    };

    const navBarProps = {
        items: ['Home','Enrol Now', 'Sign In'],
        onItemClick: handleNavBarItemClick,
    };

    return(
        <ThemeProvider theme={theme}>
            <NavBarGeneral { ...navBarProps } />
            <Container >
                <Box className={`grid-container ${isSmallScreen ? 'margin-small-screen' : 'margin-large-screen'}`}>
                    <Box className={isSmallScreen ? "center-without-bg-image " : "center"} >
                        <div className="login-form">                   
                            <h4 style={{display: 'flex', justifyContent: 'center',  marginTop: '12px'}}>Reset Password</h4>

                            <form onSubmit={handleResetPassword}>
                                <div className="form-group my-2" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: '50%', marginTop: '12px'  }}>
                                    <TextField
                                        type="password"
                                        className="form-control"
                                        id="inputUser"
                                        label="Password"
                                        InputLabelProps={{ style: { color: 'grey' }}}
                                        onChange={handlePasswordChange}
                                        required
                                        variant="outlined"
                                        fullWidth
                                    />
                                    </div>
                                    
                                    <div style={{ margin: '0 10px', width: '50%',  marginTop: '12px' }}>
                                        <TextField
                                            type="password"
                                            className="form-control"
                                            id="inputUser"
                                            label="Re-enter Password"
                                            InputLabelProps={{ style: { color: 'grey' }}}
                                            onChange={handleReEnterPasswordChange}
                                            onPaste={(e) => { e.preventDefault(); return false; }}
                                            required
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className="button-container" style={{ display: 'flex', justifyContent: 'center', marginTop: "24px" }}>
                                    <button type="submit" className="btn btn-sign-in" style={{ width: '100%', borderRadius: '36px' }}>Update Password</button>
                                </div>   
                                <div className="button-container" style={{ display: 'flex', justifyContent: 'center' }}>   
                                    <button type="reset" className="btn-clear" style={{ width: '100%', borderRadius: '36px' }}>Clear</button>
                                </div>
                                <Modal
                                    open={isFetching}
                                    style={{
                                        position: 'absolute',
                                        height: 120,
                                        width: 120,
                                        margin: 'auto'
                                    }}>
                                    <CircularProgress />
                                </Modal>
                            </form>
                            <div className="form-group my-3 row">
                                <Box display="flex" justifyContent="flex-end">
                                    <Link 
                                        className="hyper-link-right-align"
                                        href={loginURL}
                                        underline="hover">
                                        Return to Login
                                    </Link>
                                </Box>
                            </div>
                            <center style={{ marginTop: '12px' }}>{APP_NAME} : {version}</center>
                        </div>    
                    </Box>
                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
    )
}

export default ResetPasswordEmail;