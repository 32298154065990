import React, { useState, useRef, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from "axios";
import ViewPendingUser from './ViewPendingUser';
import {GridReadyEvent, FirstDataRenderedEvent} from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import DeleteEnrolledUserButton from '../UserManagement/DeleteEnrolledUserButton';
import CreateEnrolledUserBusinessButton from '../BusinessManagement/CreateEnrolledUserBusinessButton';
import '../../../pages/Common/styles.css';
import { store } from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import { 
	Box,
	CircularProgress
 } from '@mui/material';

function ManageEnrolment(props: any) {

  	const gridRef = useRef<AgGridReact<{id: String ,business_name: string, city: String, email: string, mobile: String}>>(null);

  	const manageEnrolmentURL = process.env.REACT_APP_SERVICE_URI + "enrol_your_business/view-list/";

  	const containerStyle = useMemo(() => ({ }), []);
  	const gridStyle = useMemo(() => ({ }), []);

	const storeData = store.getState();
	const navigate = useNavigate();

  	const [rowData, setRowData] = useState();
  	const [isFetching, setIsFetching] = useState(false);

	const [columnDefs, setColumnDefs] = useState([
		{headerName: 'Id', field: 'id', maxWidth:600, flex: 1, resizable: true, filter: true},
		{headerName: 'Created Date', field: 'created_at', maxWidth:600, flex: 1, resizable: true, filter: true},
		{headerName: 'Business Name', field: 'business_details.business_name', maxWidth:600, flex: 1, resizable: true, filter: true},
		{headerName: 'City', field: 'business_details.city', maxWidth:600, flex: 1, resizable: true, filter: true},
		{headerName: 'Email', field: 'business_details.email', maxWidth:600, flex: 1, resizable: true, filter: true},
		{headerName: 'Mobile', field: 'business_details.mobile', maxWidth:600, flex: 1, resizable: true, filter: true},
		{headerName: 'Status', field:  'is_completed', maxWidth:120, flex: 1, resizable: true, filter: true},
 
		{field: 'create', headerName: '', minWidth: 110, flex: 1, resizable: true, cellRenderer: CreateEnrolledUserBusinessButton }, // CreateEnrolledUserBusinessButton
        {field: 'delete', headerName: '', minWidth: 110, flex: 1, resizable: true, cellRenderer: DeleteEnrolledUserButton},
		// {field: 'viewPendingUser', cellRenderer: ViewPendingUser},
	]);

  	const onGridReady = useCallback((params: GridReadyEvent) => {
		if(didLogout()) {
            navigate("/");
        }
		setIsFetching(true);

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;

			axios.get(manageEnrolmentURL, getConfig(accessToken))
			.then((response) => {
				setRowData(response.data);
				setIsFetching(false);
			})
			.catch((error) => {
				setIsFetching(false);
				alert('Loading failed!');
			});
		})
  	}, []);

  	return (
    	<>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : null}
			<Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                    }}
            >
                <CircularProgress />
            </Modal>
			<div className='ag-grid-size'>
				
				<div style={containerStyle}>
					<div style={gridStyle} className="ag-theme-alpine">
					
						<AgGridReact
							ref={gridRef} // Ref for accessing Grid's API
							columnDefs={columnDefs}
							rowData={rowData}
							animateRows={true}
							domLayout='autoHeight'
							onGridReady={onGridReady}
						>
						</AgGridReact>
					</div>
				</div>
			</div>
      	</>
  	)
}

export default ManageEnrolment;